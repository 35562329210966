$success: #00cc96;
$border-color: #d4dde3;

//jalaj
.py-popover__panel {
    width: auto;
    min-width: 440px;
    max-width: 480px;
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, 20px);
    transform: translate(-50%, 20px);
    // display: block;
    // visibility: hidden;
    position: absolute;
    width: 250px;
    padding: 24px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid rgba(77, 101, 117, 0.07);
    box-shadow: 0 4px 36px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    font-weight: normal;

    .py-popup-close {
        position: absolute;
        cursor: pointer;
        width: 16px;
        height: 16px;
        border: 0;
        background: none;
        padding: 0;
        top: 8px;
        right: 8px;
    }
}

.amountRender-a,
.amountRender-a:hover {}

.readonly-payment-information {
    .readonly-payment-information__title {
        padding: 50px 0;
        margin: 0;
        text-align: center;
        background: $gray-100;
    }

    .invoice-payment-details {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 0;
        background: $white;
        flex-wrap: wrap;

        .invoice-payment-details__item {
            padding: 10px;
        }

        .invoice-payment-details__item__seperator {
            border-radius: 50%;
            width: 6px;
            height: 6px;
            background: #bcbdc0;
            vertical-align: middle;
            margin: 0 10px;
        }

        @media (max-width: 768px) {
            font-size: 14px;

            .invoice-payment-details__item {
                padding: 2px;
                font-size: 13px;
            }

            .invoice-payment-details__item__seperator {
                margin: 0px 5px;
            }
        }
    }
}

.invoice__record__modal__body {
    .form-control {
        font-size: 16px !important;
    }

    .placeHolderStyle {
        input {
            font-size: 16px !important;
        }
    }

    .invoice__record__modal__content {
        max-width: 400px;
        margin: 0 auto;
        padding: 2rem 0;

        .payment-view__input-group {
            .input-group {
                flex-basis: 100%;
                max-width: 100%;

                .payment-view__expire-date {
                    flex-basis: 35%;
                    max-width: 35%;
                }

                .payment-view__cvc {
                    flex-basis: 30%;
                    max-width: 30%;
                    padding: 0px 10px;
                }

                .payment-view__zip-postal {
                    flex-basis: 35%;
                    max-width: 35%;
                }
            }
        }
    }
}

div.checkbox.disable {
    label {
        opacity: 0.5;
    }
}

.public-payment-footer {
    margin-top: 2rem;
    text-align: center;

    span {
        width: 123.84px;
        height: 16px;
        // display: inline-block;
        overflow: hidden;
        vertical-align: middle;
        text-indent: -500%;
        // background: url('https://peymynt-prod.s3.us-west-1.amazonaws.com/static/payyit/icon-logo.png') left top no-repeat;
        background-size: auto 100%;
    }
}

.payment__cards__list {
    list-style-type: none;
    padding: 0;
    margin: 0px auto;
    max-width: 520px;

    .payment__cards__list__item:not(.linkable) {
        display: grid;
        grid-template-columns: 11% 29% 20% 35% auto;
    }

    .payment__cards__list__item {
        border: 2px solid $border-color;
        padding: 15px;
        position: relative;
        z-index: 1;

        .date {
            min-width: 60px;
        }

        .number {
            min-width: 90px;
        }

        .icon {
            width: 32px;
            min-width: 32px;
            display: inline-flex;

            img {
                width: 100%;
                line-height: 0px;
                display: inline-block;
                max-height: 22px;
            }
        }

        &:not(:last-child) {
            margin-bottom: -2px;
        }

        &:first-child {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }

        &:last-child {
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        &:after {
            content: "";
            width: 8px;
            height: 16px;
            border-radius: 1px;
            display: inline-block;
            border: solid #00cc96;
            border-width: 0 3px 3px 0;
            transform: translateY(-9%) rotate(45deg);
            margin-left: 10px;
            opacity: 0;
            visibility: hidden;
            margin-top: 2px;
        }

        &.linkable {
            @extend .py-text--link;
            justify-content: center;
            font-weight: $font-weight-bold;
            text-align: center;
        }

        &.is-selected {
            border-color: $success;
            background: rgba($success, 0.1);
            z-index: 2;

            &:after {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.payment__cards__list__item-big {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    cursor: auto !important;

    .number,
    .name,
    .date {
        margin: 0 1rem;
    }

    .icon {
        display: inline-flex;
        margin: 0px -5px;

        img {
            max-width: 70px;
            padding: 0 5px;
        }
    }

    &:not(:last-child) {
        margin-bottom: -2px;
    }

    &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    &:last-child {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        @extend .py-text--link;
        justify-content: center;
        font-weight: $font-weight-bold;
    }

    &.is-selected {
        border-color: $success;
        background: rgba($success, 0.1);
        z-index: 2;

        &:after {
            content: "";
            width: 8px;
            height: 16px;
            border-radius: 1px;
            display: inline-block;
            border: solid $success;
            border-width: 0 3px 3px 0;
            position: absolute;
            right: 24px;
            top: 46%;
            transform: translateY(-50%) rotate(45deg);
        }
    }
}

.bankPayment-container {
    .py-institution-search {
        position: relative;

        input {
            padding-left: 40px;
        }

        span.input-search {
            position: absolute;
            left: 10px;
            top: 5px;
            font-size: 20px;
            color: #b2c2cd !important;
        }
    }

    .py-institution-search__result {
        list-style: none;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        text-align: left;
        display: block;
        // visibility: hidden;
        position: absolute;
        top: 110%;
        z-index: 1000;
        min-width: 100%;
        padding: 8px 0;
        border-radius: 4px;
        background-color: white;
        box-shadow: 0 0 0 1px rgba(77, 101, 117, 0.1), 0 3px 10px 0 rgba(77, 101, 117, 0.2);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        height: auto;
        -webkit-transform: translateY(4px);
        transform: translateY(4px);
        overflow: hidden;
        padding: 0;
        right: 0;
        left: 0;
        top: 100%;
        border-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    .instituton-search__option {
        // background-color: #f0f4fa;
        // height: 40px;
        align-items: center;
        padding: 8px 10px;
        cursor: pointer;
        // display: -webkit-flex;
        display: block;
        position: relative;

        img {
            width: 40px;
            height: 40px;
            border-radius: 3px;
            margin-right: 0.5rem;
            vertical-align: middle;
        }

        span {
            max-width: calc(100% - 50px);
        }
    }

    .py-bank-list {
        padding: 10px;

        .py-bank-list__item-wrapper {
            padding: 0 5px;

            .institution-list__display {
                padding: 8px;
                margin-bottom: 8px;
                border-radius: 4px;
                background-color: #fff;
                background: #ecf0f3;
                box-shadow: none;
                border: none;
                transition: background 200ms;

                .intitution-list__item {
                    height: 40px;
                    text-align: left;

                    img {
                        display: inline-block;
                        vertical-align: middle;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        border-radius: 3px;
                        margin: 0;
                    }

                    span {
                        display: inline-block;
                        vertical-align: middle;
                        line-height: 1;
                        padding-left: 0.5rem;
                        font-size: 13px;
                        width: calc(100% - 50px);
                    }
                }
            }

            .institution-list__display:hover {
                cursor: pointer;
                background: #e0f4fb;
            }
        }
    }
}

.text-right.invoice-amount-cell {
    line-height: 1rem;
}

.text-right.invoice-amount-cell .amountRender-a {
    max-width: 125px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.Select.is-disabled>.Select-control {
    background-color: $input-disabled-bg;
}

.bankSign {
    font-size: 32px !important;
    height: 70px !important;
    font-family: "Cedarville cursive";
}

.selectAccount {
    .Select-control {
        height: 50px !important;
    }
}

.accountAmount {
    margin-top: 0px;
    height: 50px !important;
    font-size: 20px !important;
    text-align: right;
}

.selectAccount {
    .Select-value-label {
        img {
            margin-top: 7px;
        }

        .accountName {
            line-height: 1.9;
        }
    }
}

//jalaj ends

.invoice-view__body {
    width: 100%;
    margin: 50px auto 0;

    .invoice-view-summary {
        display: flex;
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 5px;

        &>div {
            margin-bottom: 10px;
            margin-left: 15px;
            margin-right: 15px;
        }

        .invoice-view-summary__status {
            .badge {
                margin-top: 8px;
            }
        }

        @media (max-width: 620px) {
            flex-wrap: wrap;
        }
    }
}

.invoice-view-summary__customer {
    flex-grow: 2;
}

.block-label {
    color: #4d6575;
    font-weight: 500;
    font-size: 14px;
}

.block-badge {
    background: $primary;
    color: #ffff;
    padding: 8px 15px;
    font-size: 16px;
    border-radius: 4px;
    font-weight: 500;
}

.summary__customer__name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 360px;
    display: inline-block;
    color: $primary;

    button {
        background: none;
        border: none !important;
        font-size: 1.45rem;
        color: $primary;
        padding: 0;
        font-weight: $font-weight-bold;
        line-height: 1.8;
        outline: none !important;
    }
}

.invoice-steps-card__options {}

.step-indicate {
    margin-right: 22px;

    .step-icon,
    .step-name {
        width: 40px;
        height: 40px;
        border: 2px solid $primary;
        border-radius: 40px;
        text-align: center;
        color: $primary;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        position: relative;

        .Icon {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &.step-done {
            background-color: $primary;
            color: #ffffff;
        }
    }
}

.summary__customer__name {

    button:hover,
    button:focus {
        background: none;
        border: none !important;
        outline: none;
        color: $primary;
        text-decoration: underline;
        text-decoration-skip-ink: auto;
    }
}

.summary__Amount__value {
    font-size: 23px;
}

.summary__amount__datevalue {
    font-size: 23px;
    font-weight: $font-weight-normal;
    color: $black;
}

.popover__panel {
    padding: 24px;
    width: 250px;

    strong {
        display: block;
        font-size: 16px;
        font-weight: 500 !important;
    }

    .address-box {
        span {
            display: block;
            padding: 2px 0;
            font-size: 16px;
        }
    }
}

.invoice-view-summary__customer__edit {
    a {
        display: block;
        font-size: 16px;
        font-weight: 500;
        padding-top: 5px;
    }
}

.invoice-steps-card {
    padding: 16px;
    margin-top: 16px;
    display: block;
    border-radius: 8px;
    border: 1px solid $gray-200;
    background-color: #fff;
    position: relative;
    margin-bottom: 20px;

    .step-msg.status-draft {
        font-size: 16px;
        border-radius: 8px 8px 0 0;
        border: 1px solid #b2c2cd;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fcfbe3;
        margin: -17px -17px 16px;

        span.symbol {
            font-weight: bold !important;
            width: 20px;
            height: 20px;
            background: transparent;
            border: 2px solid black;
            color: black;
            text-align: center;
            display: inline-block;
            border-radius: 50%;
            line-height: 17px;
            margin: 7px;
        }
    }
}

.vertical-line:after {
    content: "";
    width: 5px;
    height: 31px;
    background: #b2c2cd;
    position: absolute;
    bottom: -30px;
    left: 30px;
}

.vertical-line:before {
    content: "";
    width: 5px;
    height: 22px;
    background: #b2c2cd;
    position: absolute;
    top: -22px;
    left: 30px;
}

.invoice-steps-card__options {
    display: flex;

    .step-title {
        flex: 1;
        font-size: 23px;
        color: #718fa2;
        align-items: center;
        display: inherit;
    }

    .de-activate {
        .step-name {
            color: #b2c2cd;
            border: 2px solid #b2c2cd;
            cursor: pointer;
        }
    }

    .step-btn-box button {
        margin-left: 5px;
    }
}

.invoice-view-payment-section__content {
    padding-left: 65px;
    margin-top: 10px;

    .invoice-view-payment-section__content__info {
        .invoice-view-payment-section__content__info__wrapper {
            display: flex;
            justify-content: space-between;
        }
    }

    .invoice-view-payment-section__divider {
        display: block;
        height: 0;
        padding: 0;
        border: none;
        border-bottom: 1px solid #d4dde3;
        overflow: hidden;
        margin: 15px auto;
    }

    .invoice-view-payment-section__content__payment-details__actions {
        line-height: 25px;

        .py-button--link {
            background: none;
            border: none;
            color: $primary;
            // font-weight: 500;
            // padding: 0;
        }
    }

    a:not([href]):not([tabindex]) {
        color: $primary;
        cursor: pointer;
    }
}

.invoice-payment-reminders {
    margin-top: 18px;

    .invoice-payment-reminders__reminders {
        .invoice-payment-reminders__reminders__row__item {
            margin-bottom: 5px;
            margin-top: 10px;
        }
    }
}

.invoice-payment-reminders__reminders__row {
    display: flex;
    flex-flow: row nowrap;

    .invoice-payment-reminders__reminders__row__item {
        display: flex;
        flex-flow: row nowrap;
        flex-grow: 1;
        flex-basis: 0;
        flex-direction: column;
    }
}

.invoice-payment-reminders__reminders__row__item__reminder {
    .checkbox label input[type="checkbox"] {
        display: inline-block !important;
        vertical-align: middle;
        margin: 0 6px 0 0;
    }
}

.input-amount {
    .input-group-prepend {
        position: absolute;
        left: 16px;
        top: 1px;
        height: 30px;

        span {
            border: none;
            border-radius: 0;
            width: 40px;
            justify-content: center;
            font-size: 0.7rem;
            padding: 0;
        }
    }

    input {
        overflow: hidden;
        padding-left: 45px;
    }
}

.send-receipt-modal .modal-body div {
    align-items: center;

    .editable-control {
        border: 1px solid #e7f0f6;
        padding: 15px;
        border-radius: 6px;
    }
}

.box-highlited {
    box-shadow: 0 8px 32px rgba(77, 101, 117, 0.35);
}

form.sch_form label {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/*===================Send a payment Reminder Modal=========================*/

.remainder-header {
    .py-form-field {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 15px;

        .py-form-label {
            margin-bottom: 0;
            width: 22%;
            text-align: right;
            margin-right: 10px;
        }
    }
}

.img-box {
    text-align: center;

    img {
        margin: 20px 0;
        width: 40%;
        border: 2px solid #b3b9bc;
        border-radius: 4px;
    }
}

/*===================Send a payment Reminder Preview =========================*/

.invoice-reminder-email-preview__preview-img {
    width: 725px;
    margin: 0 auto;
}

.invoice-reminder-email-preview__preview-img img {
    max-width: 100%;
}

.preview-notification-banner {
    width: 725px;
    margin: 15px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background: #e0f4fb;
    border: 2px solid #bce7f6;
    color: #147494;
    border-radius: 4px;

    .alert {
        box-shadow: none !important;
    }
}

.py-notify__content p {
    margin-bottom: 0;
}

.py-notify__icon-holder {
    font-size: 24px;
}

button.btn.btn-close-tab {
    width: 175px;
    border: 1px solid #147494;
    border-radius: 31px;
    color: #147494;
    font-weight: 500;
    padding: 8px;
}

.reminder-modal.share-link {
    .py-form-email {
        width: 80%;
        margin: 0 auto;
    }
}

.invoice-tbl table tr td:nth-last-child(2) a {
    float: right;
    color: $primary;
    text-align: right;
}

.invoice-tbl table tr th:nth-last-child(2) {
    text-align: right;
}

.checkout-tbl {
    table tr {
        cursor: pointer;
    }

    table tr td,
    table tr td {
        padding: 8px !important;
        vertical-align: middle !important;
    }

    table tr th:nth-last-child(1),
    table tr th:nth-last-child(2),
    table tr td:nth-last-child(2) {
        text-align: right;
    }

    table tr td a {
        color: inherit;
    }
}

.dropdown-menu-new .dropdown-menu.show {
    right: -57px !important;
}

.react-bootstrap-table .dropdown-circle {
    position: relative;
}

/*===================recuuring page css =========================*/

.recurring-wrapper {
    padding: 0 50px;
}

.py-heading--pre-title {
    display: block;
    font-size: 15px;
    color: #7f309c;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    margin-bottom: 16px;
    letter-spacing: 0.05em;
}

.py-button--primary {
    padding: 16px 32px;
    background-color: #30dfe8;
    font-size: 19px;
    line-height: 24px;
    border-radius: 50px;
    border: none;
    display: block;
    margin: 0 auto;
}

.py-button--primary:hover {
    background-color: #17c5cf;
}

.py-button--primary:focus {
    border: none;
    outline: none;
}

.recurring-list {
    text-align: center;
    margin-top: 50px;

    .rec-icon {
        display: block;
        margin: 0 auto;
        max-width: 60px;
    }

    h2 {
        color: #718fa2;
        font-weight: normal;
        display: block;
        margin: 32px 0 16px;
        letter-spacing: -0.015em;
        font-size: 23px;
        line-height: 32px;
    }

    p {
        margin: 16px 0;
        font-size: 16px;
        line-height: 26px;
    }
}

/* Added by Ritesh */
// .invoice-add__body {
//     font-weight: $font-weight-bold;
// }

.invoice-add-info__section .card-body .invoice-view-payment-section__content {
    padding-left: 0;
}

.bankAccounts-wrapper {
    .select-bank-box {
        .Select-control {
            height: 50px;

            .Select-value {
                margin: 3px 0;
            }
        }
    }

    .bankSign {
        box-shadow: none;
        border-radius: 0px !important;

        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
}

.public-preview-page {
    width: 100%;
    max-width: 854px;
    padding: 0px 15px;
}
