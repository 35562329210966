.modal-customer {
  // box-shadow: 0 10px 20px -5px #4d6575 !important;
  min-width: 320px !important;
  max-width: 640px !important;
  border-radius: 8px !important;
  .addCustomerHeader {
    padding-bottom: 0 !important;
  }
  .addCustomerNav {
    border-bottom: none !important;
  }
  .fw-500 {
    font-weight: 600;
  }
  .addCustomerTitle {
    font-size: 23px;
    line-height: 32px;
    padding-left: 10px;
  }
  .text-right {
    text-align: right;
  }
  .headingsAdd {
    padding: 0;
    text-align: left;
    font-size: 19px;
    line-height: 24px;
    font-weight: 600;
  }
  // .py-checkbox{
  //     display: -webkit-inline-flex;
  //     display: inline-flex;
  //     cursor: pointer;
  //     -webkit-align-items: flex-start;
  //     align-items: flex-start;
  // }
  // .py-checkbox [type="checkbox"]{
  //     width: 1px;
  //     height: 1px;
  //     border: 0;
  //     clip: rect(0 0 0 0);
  //     margin: -1px;
  //     overflow: hidden;
  //     padding: 0;
  //     position: absolute;
  // }
  // .py-checkbox [type="checkbox"]:checked ~ .checkBoxDesign{
  //     background: #1f7eea;
  //     border-color: #1f7eea;
  // }
  // .py-checkbox .checkBoxDesign{
  //     border-radius: 3px;
  //     width: 16px;
  //     height: 16px;
  //     min-width: 16px;
  //     min-height: 16px;
  //     display: inline-block;
  //     position: relative;
  //     vertical-align: middle;
  //     border: 1px solid #b2c2cd;
  //     background: #fff;
  //     transition: border .1s linear,background-color .1s linear;
  //     margin-top: 3px;
  //     margin-right: 8px;
  // }
  // .py-checkbox [type="checkbox"]:checked ~ .checkBoxDesign::after{
  //     display: block;
  //     content: "";
  //     height: 4px;
  //     width: 8px;
  //     position: absolute;
  //     top: 45%;
  //     left: 50%;
  //     -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  //     transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  //     border-bottom: 2px solid white;
  //     border-left: 2px solid white;
  // }
  // .py-checkbox .checkField{
  //     display: inline-block;
  //     vertical-align: middle;
  //     margin-right: 25px;
  //     font-size: 16px;
  //     line-height: 26px;
  //     font-weight: normal;
  // }
  .mb-30 {
    margin-bottom: 30px;
  }
}
.pd-r-20 {
  padding-right: 20px;
}

.reactStrap-design.invoice-input-bold {
  text-align: right;
  font-size: 30px;
  display: block;
  width: 100%;
  height: 40px;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.recurring-list-table {
  .react-bootstrap-table {
    table {
      td:last-child {
        .dropdown {
          float: right;
        }
      }
      thead {
        tr {
          th:nth-child(6) {
            text-align: right;
          }
        }
      }
    }
  }
}
.automatic_payment_warning {
  border-radius: 6px;
  background-color: #fbeaea;
  text-align: center;
  margin-top: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    padding: 0px;
  }
}

.automatic_payment_customer-points {
  margin: auto auto;
}

.automatic_payment_addCard-heading {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 10px;
}

.automatic_payment_icons {
    text-align: center;
    min-height: 30px;
    margin: 25px 0
}

.open > .dropdown-menu { 
  display: block !important; 
}

@media ( max-width: 980px ){
  .recurring-list-table {
    .react-bootstrap-table {   
      max-width: 100%;
      .table {
        .tooltip {
          display: none !important;
        }
      }
    }
  }
}