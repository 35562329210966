// Commented as it was clashing with invoice public view -> bank payment tabs
// .nav-tabs .nav-link {
//     border-bottom: 4px solid transparent !important;
//     border: none !important;
// }

.invoice-view-header {
    padding: 0 0 15px;
    border-bottom: 1px solid #d3d3d3;

    .py-header--page {
        margin-bottom: 0;        
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .py-heading__title {
            margin-bottom: 10px;
        }
    }
}

.input-placeholder::placeholder {
    font-size: 13px;
}

.recurring-list-table table tr td {
    font-size: 16px;

    p {
        margin-bottom: 0;
        font-size: 14px;
        color: #687578;
    }

    .tooltip-btn span {
        background: #136acd !important;
        color: #fff !important;
        text-decoration: none !important;
        padding: 2px 12px;
        border-radius: 4px;
        cursor: pointer;
    }
}


.invoice-steps-card {
    .py-notify--warning {
        display: flex;
        justify-content: space-between;
        background: #fcfbe3;
        border: 1px solid #f6f2ac;
        padding: 0 20px;

        .py-notify__icon-holder-holder {
            padding-top: 12px;
        }

        .py-notify__r-content-wrapper {
            padding: 16px;
            color: #7c760e;
        }
    }
}

.py-decor-icon {
    width: 64px;
    height: 64px;
}

.invoice-steps-card__content {

    .py-content--narrow-send-type {
        display: flex;

        button.py-button--secondary {
            min-height: 225px;
            display: flex;
            width: 47%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: rgba(0, 0, 0, 0);
            border: 1px solid #b2c2cd;
            padding: 0 30px;
            border-radius: 4px;

            &:first-child {
                margin-right: 30px;
            }
        }
    }

    .recurring-invoice-view-send-auto-section__form {
        margin-top: 50px;

    }

}

.py-box--large.is-highlighted {
    box-shadow: 0 8px 32px rgba(77, 101, 117, 0.35);
    border-radius: 12px;
    border-color: transparent;
}

.recurring-invoice-list-filters--container {
    margin-bottom: 20px;
}

.recurring-invoice-list-table-filters--container {
    margin-bottom: 20px;
}

.recurring-invoice-view-activate-section {
    align-items: center;
    margin-left: 50px;
    margin-right: 50px;
    text-align: center;
    padding: 50px;
}

.recurring-invoice-view-activate-section__box__title {
    margin-top: 10px;
}

.recurring-invoice-view-activate-section__box__subtitle {
    margin-left: 75px;
    margin-right: 75px;
}


.recurring-list-table {
    .py-table--condensed {

        table {

            .py-table__row {

                th {

                    &:nth-child(4),
                    &:nth-child(5) {
                        width: 110px;
                    }
                }

                .py-table__cell {
                    width: auto;
                }

                td {
                    vertical-align: middle;

                    &:nth-child(3) {
                        min-width: 310px;
                    }

                    &:nth-child(4),
                    &:nth-child(5) {
                        width: 110px;
                    }

                    &:nth-child(6) {
                        text-align: right;
                        .py-table__cell-content {
                            float: right;
                        }
                    }
                }
            }
        }
    }
}

.py-box.is-active {
    background: rgba($primary, .1);
    color: inherit;
    border-width: 0;
}

.invoice-steps-card__content {
    .py-form-field__label {
        width: 25%;
    }

    .py-form-field__element {
        width: 75%;
    }

    .content-wrapper__main {
        padding: 0;
    }
}

.date-calender {
    position: relative;

    &:after {
        content: "\F073";
        font-family: "Font Awesome 5 Pro";
        position: absolute;
        right: 2px;
        top: 50%;
        transform: translateY(-50%);
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        pointer-events: none;
        color: #b2c2cd;
        z-index: 2;
        border-radius: 5px;
        background-color: #ffffff;
    }
}



.schedule-settings-container__row__content {
    display: flex;
    align-items: center;
    flex-flow: row wrap;

    & > * {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .py-select--native,
    .react-datepicker-wrapper,
    .Select {
        width: 100%;
        min-width: auto;
        // margin: 0 8px;
    }

    // .py-form__element__small {
    //     min-width: 100px;
    //     width: 120px;
    // }

    // .py-form__element__xsmall {
    //     width: 80px;
    //     min-width: 50px;
    // }
}

.recurring__schedule__container {
    margin-top: 1rem;

    .py-box {

        .py-select--native,
        .form-control {
            margin: 0;
        }

        .py-text {
            margin: 0 8px 0 8px;
            position: relative;
            background: #FFB191;
            color: $black;
            padding: 4px;
            height: 24px;
            line-height: 16px;
            border-radius: 4px;
            font-size: 14px;

            &:before,
            &:after {
                content: '';
                width: 10px;
                height: 2px;
                background: #FFB191;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            &::before {
                left: -8px;
            }

            &::after {
                right: -8px;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }

    .py-form__element__small {
        min-width: 115px;
    }
}

.recurring__schedule__content-joined {
    display: inline-flex;
    white-space: nowrap;

    .form-control {
        margin: 0;

        &:focus {
            z-index: 2;
        }

        ~ .py-select--native {
            margin: 0;
            flex: 1;
            .form-control {
                margin: 0;
            }
        }
    }

    .py-select--native {
        margin-right: -1px !important;
    }

    .react-datepicker-wrapper {
        min-width: 160px;
    }

    .date-calender {
        min-width: 160px;
        margin-left: 15px;
    }


}

.recurring-invoice__preview {
    .invoice-preview__body {
        width: 820px;
    }
}

.recurring__invoice__form__header {
    .py-form-field--inline {
        align-items: center;
    }

    .py-form-field__label {
        width: 80%;
    }
}

.danger{
    border-color: red
}


.recurring__schedule__content-joined.group-box {

    .form-control {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
    }

    .Select-control {
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        margin-left: -1px;
    }

}


.recurring__schedule__container {
    .py-box {
        border: none;
        display: flex;
        flex-wrap: wrap;
        align-items: self-end;
        .py-box__header {
            border-bottom: none;
            width: 180px;
            flex-wrap: wrap;
        }
        .py-box--content {
            width: calc(100% - 180px);
            padding: 0px;
        }
    }
}

