.btn {
    border-radius: 100px;
    font-weight: 700 !important;
    position: relative;

    &.btn-circle {
        border: 1px solid #d2d2d2;
        background: none;
        color: #1c252c;
        width: 32px;
        height: 32px;
        padding: 0;
        line-height: 0px;
        text-align: center;
        min-height: 32px;

        &:after {
            display: none;
        }
    }
    &.btn-small {
        padding: 10px 18px;
        font-size: 12px;
    }
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    border-color: inherit;
}

.border-left.dropdown-toggle {
    z-index: 2;
    position: relative;
}

.btn-default {
    background-color: #d4dde3;

    &:hover {
        background-color: #b4bfc7;
    }
}

.wellet-button {
    border-radius: 5px;
    background-color: #F3F2F2;
    padding: 0px 30px;
    height: 50px;
    line-height: 50px;
    border: none;

    &:hover {
        background-color: #ECF0F3;
    }
}

.btn-link {
    border: none;
    background: none;
    color: #136acd;
    outline: none;

    &:hover,
    &:focus {
        outline: none;
        color: #136acd;
    }
}