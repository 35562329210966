

.receipt-preview-email__box {
    margin: 50px auto;
    width: 500px;

    .preview-email__box {
        padding: 30px;
        border: 1px solid #b2c2cd;
        border-radius: 8px;

        .receipt-preview-email__box__header__title{
            text-align: center;  
        }

        .receipt-preview-email__box__header__details {
            color: #b3b9bc;

            span {
                display: block;
                text-align: center;
            }
        }

        .receipt-preview-email__box__address__details{
            text-align: center;
            margin-top: 20px;

           .address__field{
             font-weight:600;
           }
        }

        .receipt-preview-email__box__amount {
            text-align: center;
            padding: 15px 0;
            border-top: 1px solid #d4dde3;
            border-bottom: 1px solid #d4dde3;
            margin: 24px 0;
        }

        .receipt-preview-email__box__amount__payment-method {
            text-align: center;
            margin-bottom: 20px;
        }

        .receipt-preview-email__box__amount__view-invoice-button {
            padding: 11px 45px;
            margin: 0 auto;
            display: block;
        }

        .receipt-preview-email__box__amount__view-receipt-link {
            text-align: center;
            margin: 10px 0;

            a {
                font-weight: 600;
            }
        }

        .receipt-preview-email__box__amount__paid-stamp {
            margin: 40px;
            text-align: center;
        }

        .receipt-preview-email__box__footer {
            text-align: center;
        }
    }

    .divider-full-width {
        margin-left: -30px;
        margin-right: -30px;

        .py-divider {
            display: block;
            height: 0;
            padding: 0;
            border: none;
            border-bottom: 1px solid #d4dde3;
            overflow: hidden;
            margin: 24px auto;
        }
    }

    .receipt-preview-email__footer {
        text-align: center;
        padding: 15px 0 0;

        img {
            width: 40px;
        }
    }

}



.mailpreview__box {
    border: 2px solid #ccc;
    width: 600px;
    margin: 0 auto;
    border-radius: 5px;
    padding: 15px;

    .mailpreview__image {
        max-width: 250px;
        max-height: 250px;
        margin: 0 auto;
        padding: 30px;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .mailpreview__details {
        background: #e9eef1;
        padding: 40px;
        text-align: center;

        .mailpreview__details__subtitle {
            font-size: 26px;
            font-weight: 600;
            margin: 15px 0 8px 0;
        }
    }

    .mailpreview__details__button {
        margin-top: 15px;
        display: flex;
        justify-content: center;
    }

    .mailpreview__contact {
        text-align: center;
    }
}

.message-body-wrapper{
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    padding: 3%;
    font-size: 0.9vw;
    background-color: #f5f5f5ba;
    color: #b9b9b9e6;
    max-height: 18vh;
    overflow-y: scroll;
    cursor: no-drop;
    .emoji{
        &::before{
            content: "\2B07";
        }
    }
    span{
        text-overflow: ellipsis;
        overflow-wrap: anywhere;
    }
}
/* Added by Ritesh */
