.py-banner {
    border: 1px solid #ededed;
    background-color: #ffffff;
    border-radius: 6px;
    margin: 10px ;
    padding: 12px 15px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    z-index: 1;
    overflow: hidden;
    min-height: 48px;
    transition: 0.3s;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #e01941;
        opacity: 0.1;
        z-index: -1;
    }

    .py-banner-content {
        display: flex;
        align-items: center;

        &.have-button {
            max-width: 75%;
        }

        .badge {
            margin-left: 10px;
            border-radius: 5px;
            padding: 5px 10px;
        }

        .badge-danger {
            background-color: #e01941;
            color: #ffffff;
        }
    }

    &-actions {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    .info-icon {
        margin-right: 10px;
        color: #f55769;
        line-height: 0;
        display: inline-block;
        border-radius: 5px;
        overflow: hidden;
        min-width: 20px;

        .Icon {
            width: 20px;
            height: 20px;
        }
    }


    .py-banner-desc {
        font-size: 16px;
        p {
            margin: 0;
        }
    }

    .banner-action {
        background: #ffffff;
        border: 1px solid #ededed;
        padding: 8px 24px;
        border-radius: 8px;
        margin-left: auto;
        transition: 0.3s;

        &:hover {
            background-color: #e1183c;
            color: #ffffff;
            border-color: #e1183c;
        }
    }

    .cencel-link {
        margin-left: 15px;
        padding: 0;
        line-height: 32px;
        text-align: center;
        border: none;
        background: none;
    }
}