.global-alert{
 .alert{
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    box-shadow: none;
    display: block;
 }
 .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}
.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}
.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}
.alert .close {
    margin-top: 10px;
    margin-left: 15px;
}
.fade:not(.show) {
    opacity: 0;
    display: none;
}
.alert-button {
    :disabled {
        color: #147494 !important;
    }
}
}