$spaceamounts: (8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96, 104, 112); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .Margin__#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
    
    .Padding__#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}



// view port heights generator
@for $i from 1 through 10 {
  $height: ($i * 10);

  .height-#{$height} {
    min-height: #{$height}vh;
  }
}


.py-text--divider {
  font-size: 14px;
  color: $black;
  text-align: center;
  border-bottom: 2px solid $border-color;
  line-height: 0em;
  display: block;
  margin: 2rem 0;

  span {
      background: $white;
      padding: 0 8px;
      color: #4d6575;
  }
}



.u-o--hidden {
  overflow: hidden;
}

.p-1 {
  padding: 8px;
}

.p-2 {
  padding: 16px;
}

.p-3 {
  padding: 24px;
}

.p-4 {
  padding: 32px;
}

.p-5 {
  padding: 40px;
}

.p-6 {
  padding: 48px;
}

.p-7 {
  padding: 46px;
}


.bg-default {
  background: #E6E6E1;
}

.text-sm {
  font-size: 12px !important;
}
.text-muted {
  color: rgba($black, .64) !important;
}

.bg\:success-100 { 
  background: rgba($primary, .1);
}

.bg\:yellow-100 {
  background: rgba($yellow, .1) !important;
}
.hover\:primary-100:hover {
  background: rgba($primary, .1) !important;
}

.hover\:primary-200:hover {
  background: rgba($primary, .2) !important;
}

.mn-w-50 {
  min-width: 50px;
}

.mn-w-100 {
  min-width: 100px;
}

.mn-w-150{
  min-width: 150px;
}

.mn-w-200 {
  min-width: 000px;
}

.mn-w-250 {
  min-width: 250px;
}

.mn-w-300 {
  min-width: 300px;
}