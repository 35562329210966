.contemporary-template .wv-table__header {

  .wv-table__cell,
  .wv-table__cell--amount {
    text-transform: inherit;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #fff;
  }
}

.wv-table__cell .middle-align {
  text-align: center;
}

.contemporary-template {
  font-family: Helvetica, Arial, sans-serif;

  .wv-heading--subtitle,
  .wv-heading--title,
  .wv-table__cell,
  .wv-table__cell--amount,
  .wv-text,
  .wv-text--strong {
    font-family: Helvetica, Arial, sans-serif;
  }

  font-size: 14px;
  position: relative;
  padding: 16px;
  min-height: 1024px;
  box-sizing: border-box;
  background: #ffffff;
  color: #1c252c;

  .wv-table__cell,
  .wv-table__cell--amount,
  .wv-text,
  .wv-text--strong {
    font-size: 14px;
  }

  .wv-heading--title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 36px;
    font-weight: 300;
  }

  .wv-heading--subtitle {
    word-wrap: break-word;
    color: gray;
    font-size: 14px;
    margin-top: 0;
    line-height: 16px;
  }

  .address {
    margin-top: 10px;
  }

  .address__field .wv-text {
    line-height: 16px;
  }

  .wv-table,
  .wv-text {
    font-size: 14px;
  }

  .wv-table__cell {
    padding-left: 15px;
    padding-right: 15px;
    vertical-align: top;
    white-space: pre-line;
    word-break: break-word;

    &:first-child {
      padding-left: 30px;
    }
  }

  .wv-table__cell--smaller {
    width: 105px;
    white-space: pre-line;
  }

  .wv-table__cell--amount {
    padding-left: 15px;
    padding-right: 15px;
    width: 125px;
    vertical-align: top;
    white-space: pre-line;

    &:last-child {
      padding-right: 30px;
    }
  }

  .wv-table__row {
    border-bottom: inherit;

    .wv-table__cell--nested {
      color: #4c5357;
      font-size: 14px;
    }
  }
}

.contemporary-template__divider--bold .wv-divider {
  border-width: 3px;
  margin-top: 0;
  margin-bottom: 16px;
}

.contemporary-template__divider--small-margin .wv-divider {
  margin: 12px auto;
}

.contemporary-template__divider--full-width .wv-divider {
  margin-left: -16px;
  margin-right: -16px;
}

.contemporary-template__header {
  display: flex;
  margin: 25px 14px 0;
  justify-content: space-between;
}

.contemporary-template__header__logo img {
  max-height: 200px;
  max-width: 300px;
}

.contemporary-template__header__info {
  text-align: right;
  width: 442px;
}

.contemporary-template .invoice-template-details .wv-table__row--header .wv-table__cell:first-child {
  min-width: 125px;
}

.contemporary-template__metadata {
  margin-left: 14px;
  margin-right: -16px;
  display: flex;
}

.contemporary-template__metadata__customer {
  flex-grow: 1;
  display: flex;
  width: 57%;
}

.contemporary-template__metadata__customer__wrapper {
  display: flex;
}

.contemporary-template__metadata__customer--billing {
  flex-grow: 1;
  padding-right: 10px;
}

.contemporary-template__metadata__customer--shipping {
  flex-grow: 3;
  padding-right: 10px;
}

.contemporary-template__metadata .invoice-template-details {
  margin-top: -5px;

  .wv-table__row:hover {
    background: none;
  }

  .wv-table__cell {
    padding-top: 0;
    padding-bottom: 0;

    &:first-child {
      text-align: right;
    }

    &:last-child {
      padding-left: 0;
    }
  }

  .wv-table__row:last-child {
    background: #f4f5f5;

    .wv-table__cell {
      padding-top: 5px;
      padding-bottom: 5px;

      &:nth-child(2) {
        padding-right: 30px;
        min-width: 125px;

        .wv-text--body {
          font-weight: 600;
        }
      }
    }
  }
}

.contemporary-template__items {
  margin-top: 26px;
  margin-right: -16px;
  margin-left: -16px;

  .no-items-message {
    text-align: center;
  }
}

.contemporary-template__items__column--center {
  text-align: center;
}

.contemporary-template__totals {
  display: flex;
  margin-bottom: 35px;
  margin-left: -16px;
  margin-right: -16px;
}

.contemporary-template__totals__blank {
  flex-grow: 5;
}

.contemporary-template__totals__amounts {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-right: 30px;
}

.contemporary-template__totals__amounts__line {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.contemporary-template__totals__amounts__line__amount {
  margin-left: 30px;
  min-width: 125px;
}

.contemporary-template__memo {
  margin: 0 14px 35px;
  white-space: pre-line;
  word-wrap: break-word;

  >span {
    margin: 8px 0;
    display: block;
  }
}

.con-temp-header_title {
  font-weight: 300 !important;
}

.con-temp-address {
  line-height: 16px;
  font-size: 14px;
}

.template-metadata-customer-billing {
  strong {
    display: block !important;
    margin-bottom: 0px !important;
  }
}

.contemporary-template__metadata__customer {
  .template-metadat-customer-address-header {
    margin-bottom: 0px !important;
  }
}

.py-table--plain {
  td {
    padding: 3px 7px 3px 5px !important;
  }

}

.contemporary-template__items {
  .bodr_btm:hover {
    background-color: rgba($border-color, .2);
  }
}

.contemporary-template__header {
  padding-right: 0px !important;
}

.contemporary-template {
  hr {
    margin: 24px -16px;
    width: calc(100% + 32px);
  }
}

// .py-invoice-template__row.py-invoice-template__history{
// .py-invoice-template__row-amount{
//     span{
//         font-weight: $font-weight-bold;
//     }
// }
// }

.py-invoice-template__history.total-amount {
  line-height: 35px;
}

.contemporary-template .contemporary-template__items .table tbody tr:last-child {
  border-bottom: 3px solid #dbe3e6;
}