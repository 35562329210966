.reciept-container {
  header {
    display: block;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    margin: 0 0 18px;
    width: 100%;
    background: #fff;
    z-index: 10;
  }

  header .container {
    display: block;
    width: 96%;
    margin: 0 auto;
  }

  header .container::after {
    content: '';
    display: block;
    clear: both;
  }

  header .txt {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 200px - 8px);
    text-align: left;
    line-height: 40px;
  }

  header .goBack {
    display: inline-block;
    width: 200px;
  }

  header .goBack a {
    display: block;
    line-height: 40px;
    border: 1px solid #b2c2cd;
    border-radius: 20px;
    text-align: center;
    font-weight: 700;
    color: #4d6575;
    text-decoration: none;
  }

  .Receipt {
    display: block;
    width: 460px;
    margin: 80px auto 0;
    border: 1px solid #e1e1e1;
    text-align: center;
    padding-top: 27px;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    background: #fff;
  }

  .Receipt .logo {
    display: block;
    margin: 0 auto;
  }

  .Receipt .logo img {
    width: 180px;
  }

  .Receipt h2 {
    color: #2f2f36;
    font-size: 32px;
    line-height: 32px;
  }

  .Receipt span.receiptNo {
    display: block;
    font-size: 18px;
    font-weight: $font-weight-bold;
    margin: 0 0 5px;
    text-transform: capitalize;
  }

  .Receipt span.for {
    display: block;
    font-size: 16px;
    color: #a0a0a5;
    margin: 0 0 22px;
  }

  .Receipt p {
    font-size: 12px;
    line-height: 18px;
    color: #a0a0a5;
    font-weight: $font-weight-bold;
    margin: 0 0 20px;
  }

  .Receipt .sep {
    display: block;
    position: relative;
    border-bottom: 1px solid #e1e1e1;
    margin: 0 0 58px;
  }

  .Receipt .sep::before {
    content: '';
    display: block;
    width: 34px;
    height: 14px;
    background: #fff url(/assets/checkmark-reminder-email.jpg) center no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .Receipt .payD {
    display: block;
    margin: 0 0 27px;
  }

  .Receipt .payD .amt,
  .Receipt .payD .method {
    display: block;
    border-top: 1px solid #e1e1e1;
    padding: 12px 0;
    margin: 0 28px;
  }

  .Receipt .payD .amt {
    font-size: 18px;
    line-height: 40px;
  }

  .Receipt .payD .method {
    font-size: 12px;
    text-transform: uppercase;
    line-height: 40px;
  }

  .Receipt .thanks {
    padding: 20px 27px;
    border-top: 1px solid #e1e1e1;
    background: #f9f9f9;
    font-size: 14px;
  }

  .Receipt .thanks a {
    color: #136acd;
    text-decoration: none;
    font-weight: $font-weight-bold;
    font-size: 14px;
  }

  .Receipt .foot {
    display: block;
    height: 26px;
    background: #f0f0f0;
    border-top: 1px solid #e1e1e1;
  }

  .reciept-business-details {
    text-align: left;
    padding: 20px 30px;
  }

  .businessInfo-reciept {
    font-weight: bold;
    font-size: 14px;
    color: #4d6575;
  }

  .poweredBy {
    display: block;
    width: 460px;
    margin: 0 auto;
    padding: 20px 0;
    text-align: center;
  }

  .poweredBy .poweredLogo {
    display: inline-block;
    background: url(/assets/powered-by.jpg) center no-repeat;
    background-size: 132.8px auto;
    width: 174px;
    height: 40px;
    border: 1px solid #e1e1e1;
    border-radius: 20px;
  }
}


.receipts-wrapper {
  .progress-container {
    position: relative;
    width: 100%;
    height: 8px;
    border-radius: 8px;
    visibility: hidden;
    overflow: hidden;

    &.show {
      visibility: visible;
    }

    .progress,
    .track {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    .track {
      background: #d4dde3;
      z-index: 1;
    }

    .progress {
      background: #78c3fc;
      transition: width 0.6s ease;
      z-index: 2;
    }
  }

  .page.header {
    margin-bottom: 24px;
  }

  .receipts-list {
    .react-bootstrap-table-pagination {
      text-align: left;
    }

    tr {
      cursor: pointer;
    }

    th,
    td {
      padding: 12px 15px;

      &:nth-child(2) {
        width: 90px;
      }

      &::nth-child(4) {
        min-width: 55px;
      }

      &::nth-child(5) {
        text-align: right;
        min-width: 100px;
      }

      &:last-child {
        width: 50px;
        text-align: center;
      }
    }

    th {
      padding-top: 20px;
      padding-bottom: 8px;
      font-weight: $font-weight-bold;
      vertical-align: bottom;

      &:first-child {
        width: 130px;
      }

      &:nth-child(2) {
        width: 140px;
      }

      &:nth-child(4) {
        width: 90px;
      }

      &:nth-child(5) {
        width: 100px;
        text-align: right;
      }

      &:nth-child(6) {
        width: 50px;
      }
    }

    td {
      vertical-align: middle;
      line-height: 22.4px;

      &:nth-child(5) {
        width: 100px;
        text-align: right;
      }
    }

    .react-bootstrap-table {
      max-width: 100%;
    }
  }

  .dropdown-menu-new2 .dropdown-menu.show {
    right: 0 !important;
    top: 0 !important;
    transform: translate3d(-36px, 0px, 0px) !important;
  }
}

.receipt-details {
  max-width: 800px !important;
  width: 100%;


  .modal-body {
    padding: 1rem 1.5rem;
  }

  .receipt-container {
    display: flex;
    align-items: flex-start;

    @media (max-width: 992px) {
      flex-wrap: wrap;

      .preview-section {
        width: 100%;
        text-align: center;
      }

      .py-form-field--inline .py-form-field__label,
      .py-form-field--inline .py-form-field__label--align-top {
        width: 25%;
      }

      .py-form-field--inline .py-form-field__element {
        width: 75%;
      }
    }
  }

  .information-section {
    flex-grow: 1;

    .py-form-field__element {
      .tax-values {
        max-width: 200px;
      }
    }

  }

  .py-form__element__medium {
    width: inherit;
  }

  .media-wrapper {
    display: block;
    cursor: pointer;

    img {
      max-width: 220px;
      max-height: 350px;
      display: block;
      margin: auto auto 16px;
    }

    span {
      font-weight: $font-weight-bold;
      display: block;
      color: $primary;
    }
  }

  .source {
    display: block;
    color: #4d6575;
    margin: 1em 0;
    font-size: 14px;
    line-height: 1.4;
  }

  .tax-name {
    display: inline-block;
    margin-top: 7px;
  }
}

/* 
.edit-mode {
   .col-form-label {
     padding: 0 11px 0;
     line-height: 40px;
   }

   input.form-control {
     max-width: 190px;
     font-size: 16px !important;
     color: #1c252c !important;
     vertical-align: middle;
     outline: none;
     appearance: none;
     border-radius: 4px;
     cursor: text;
       padding: 8px 10px 6px;
       transition: border .1s linear;
       min-height: 40px;
   }
   .required {
     color: #c22929;
   }

   .tax-header {
     .col-form-label {
       line-height: 1.414;
     }
   }

   .tax-values {
     .col-form-label {
       padding-top: 15px;
       line-height: 1.414;
     }
   }
 }
  */

.recenpt-template {
  overflow: auto;
  max-width: 100vw;
}

.receipt-details {
  .modal-content {
    width: 98%;
  }
}

.py-table.payments-list.payout-list {
  min-width: inherit;

  @media (max-width: 768px) {
    .detail-row {
      .col-4 {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 15px 0px;
        text-align: center;

        .card_wrapper {
          float: none;
          display: inline-block;
        }
      }
    }
  }
}