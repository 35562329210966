.dashboard-wrapper {

    .content {
        display: flex;
        align-items: flex-start;

        @media (max-width: 920px) {
            flex-wrap: wrap;
        }
    }
}

.activity-wrapper {
    max-width: 340px;
    width: 100%;
    flex-shrink: 0;

    @media (max-width: 1024px) {
        max-width: 280px;
    }

    @media (max-width: 920px) {
        max-width: 100%;
    }
}

.financial-wrapper {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: -30px;

    @media (min-width: 921px) {
        margin-left: 20px;
        padding-left: 20px;
    }

}

.widget-wrapper {
    margin: 24px auto;

    &+.widget-wrapper {
        margin-top: 48px;
    }

    .title-container {
        margin-bottom: 8px;
    }

    .content-container {
        display: block;
        margin-bottom: 12px;
        border-radius: 8px;
        border: 1px solid #b2c2cd;
        background-color: #ffffff;
        width: 100%;
        padding: 12px;
    }

    .tables-container {
        table {
            width: 100%;
        }

        tbody {
            tr:hover {
                background: #e6f1ff;
            }
        }

        th {
            padding: 20px 8px 8px;
            font-weight: bold;
            vertical-align: bottom;
            border-bottom: 2px solid #b2c2cd;

            &:nth-child(n+2) {
                text-align: right;
            }

            .icon {
                width: 20px;
                height: 20px;
                font-size: 20px;
            }
        }

        td {
            padding: 8px;
            font-weight: normal;
            font-size: 16px;
            border-bottom: 1px solid #d4dde3;

            &:nth-child(n+2) {
                text-align: right;
            }
        }
    }

    .or-separator-container {
        margin: 24px auto;
        display: flex;
        align-items: center;
        justify-content: center;

        hr {
            border-top: 1px solid #d4dde3;
            width: 43px;
            margin: 0;
        }

        .text {
            margin: 0 1em;
            line-height: 1;
        }
    }

    .see-all-container {
        text-align: center;

        a {
            font-weight: 700;
            text-transform: capitalize;

            &:hover {
                text-decoration: underline;
                color: $blue-light;
            }
        }
    }

    .highcharts-container {
        margin: auto;
    }

    .info-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .info {
            font-weight: normal;
        }

        a {
            font-weight: bold;
        }
    }

    .chart-container {
        position: relative;

        @media (max-width: 660px) {
            .HighchartsReact>div {
                overflow: auto !important;
            }
        }
    }


    .no-data-container {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 340px;

        .image-container {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        img {
            opacity: 0.4;
            display: block;
            margin: 0 auto;
        }

        .placeholder-container {
            position: relative;
            font-size: 13px;
            border-radius: 5px;
            color: #999;
            z-index: 99;
            background: #F4F2D4;
            padding: 10px;
            width: 250px;

            span {
                display: block;
            }

            .heading {
                color: #000;
                margin: 0;
                font-size: 13px;
                font-weight: bold;
            }
        }
    }
}

.widget--title {
    font-size: 23px;
    font-weight: 500;
    line-height: 1.4;
    color: $black;
    margin: 0;
}

.bank-container {
    margin-bottom: 20px;

    .bank--name {
        font-size: 16px;
        line-height: 30px;
        font-weight: 400;
        color: #444;
        margin: 0;
        text-rendering: optimizelegibility;
    }

    .bank--logo {
        width: 20px;
        margin-right: 5px;
        vertical-align: sub;
    }

    .last-updated-container {
        color: #4d6575;
        font-size: 13px;
        line-height: 1.5em;
        min-height: 20px;
        margin-bottom: 10px;
        margin-right: 4px;
        display: flex;
        align-items: center;

        .icon {
            width: 20px;
            height: 20px;
            vertical-align: middle;
        }

        a {
            padding: 0;
            font-weight: 500;
            border: none;
            margin: 0;
            color: inherit;
            font-size: inherit;
            text-decoration: underline;
        }
    }

    .account-container {
        position: relative;
        border-radius: 5px;
        box-sizing: border-box;
        background-color: #D0F4FC;
        margin-bottom: 5px;
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
    }

    .account-container-error {
        position: relative;
        border-radius: 5px;
        box-sizing: border-box;
        background-color: #f7d6d5;
        margin-bottom: 5px;
        padding: 15px;
        display: flex;
        justify-content: space-between;
    }


    .account--name,
    .account--balance {
        width: 48.9%;
        flex-shrink: 0;
        line-height: 20px;
        margin: 0;
        text-transform: uppercase;
    }

    .account--balance {
        color: #777;
        text-align: right;
    }
}


.error {
    color: red;

    a {
        color: red;
        text-decoration: underline;
        margin: 0px;
    }
}

.bank-widget {
    .add-bank-account-container {
        margin: 5px auto;
        text-align: center;
    }

    .statement-link-container {
        text-align: center;
        margin-top: 15px;
    }

    .manage-accounts-container {
        padding-top: 10px;
        text-align: center;

        a {
            font-size: 12px;
        }
    }
}

.things-to-do-widget {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        padding: 10px 0;
        line-height: 20px;
        font-weight: bold;

        a {
            color: $blue;

            &:hover {
                color: $blue-light;
                text-decoration: underline;
            }
        }
    }
}

.payable-owing-widget {
    .tables-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (min-width: 660px) {
            table {
                max-width: calc(50% - 15px);
            }
        }


    }
}

.overdue-widget {
    ul {
        list-style: disc;
        margin: 16px 0 16px 0;
        padding: 0;

        li {
            margin: 5px;
            text-transform: capitalize;
            display: flex;
            justify-content: space-between;

            &:first-child {
                margin-top: 0px;
            }

            &:last-child {
                margin-bottom: 0px;
            }

            a {
                font-weight: 700;

                &:hover {
                    text-decoration: underline;
                    color: $blue-light;
                }
            }
        }
    }

    .list-title {
        color: #4d6575;
        font-size: 14px;
        line-height: 1.4;
        font-weight: 500;
    }

    hr {
        border-top: 1px solid #d4dde3;
        overflow: hidden;
        margin: 24px auto;
    }
}

.widget-wrapper.bank-widget {
    .content-container {
        padding: 30px 25px;
        border-color: #b2c2cd;
    }

    .CentralBankingWidget {
        text-align: center;

        .bank-illus {
            max-width: 150px;
            display: inline-block;
            margin: 0px;
        }
    }
}




.dh-widget {
    margin-bottom: 60px;

    .widget-title {
        font-size: 24px;
        color: #1c252c;
        margin-bottom: 15px;
    }

    .widget-info {
        color: #1c252c;
        font-size: 14px;
        font-weight: 400;
        margin-top: -10px;
        margin-bottom: 15px;
    }

    .widget-sub-title {
        font-size: 14px;
        font-weight: 500;
        color: #4d6575;
        margin-bottom: 15px;
    }

    .options-container {
        margin: 0 -7.5px;

        .option {
            font-weight: 500;
            font-size: 14px;
            margin: 0px 7.5px 10px 7.5px;
            display: inline-block;
            color: #8AA2B2;
            cursor: pointer;

            &.active {
                color: #1c252c;
            }
        }
    }

    .widget-box {
        padding: 15px;
        border-radius: 10px;
        border: 1px solid #ededed;
        background-color: #ffffff;
    }

    .widget-list {
        list-style: none;
        margin: -5px 0px;
        padding: 0px;

        li {
            padding: 5px 0px;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
        }

        a {
            font-weight: 700;
        }
    }

    .widget-footer-link {
        text-align: center;
        margin-top: 15px;

        a {
            font-weight: 700;
            text-transform: capitalize;
        }
    }

    a:hover {
        text-decoration: underline;
    }

    &.widget-overdue {
        .widget-list {
            .b_name {
                text-align: left;
                padding-right: 15px;
                overflow-wrap: break-word;
            }
            .amount {
                overflow-wrap: break-word;
                padding-left: 15px;
            }
        }
    }


    .widget-table {
        width: 100%;

        thead tr {
            border-bottom: 2px solid #d4dde3;

            th {
                padding: 10px;
                padding-top: 0px;
            }
        }

        tbody tr {
            border-bottom: 1px solid #ededed;
            width: 100%;

            th,
            td {
                padding: 10px;
            }

            td:last-child {
                text-align: right;
            }

            &:hover {
                background-color: #E6F1FF;
            }
        }
    }

    &.payable-owing-widget {
        .widget-box {
            display: flex;
            justify-content: space-between;
        }

        .widget-table {
            width: calc(50% - 15px);
        }
    }

    &.net-income-widget {

        th:first-child,
        td:first-child {
            width: 60%;
        }

        tr {

            th:nth-child(2),
            th:nth-child(3),
            td:nth-child(2),
            td:nth-child(3) {
                text-align: right;
            }
        }
    }

    &.quick-link-widget {
        .quick-link-list {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            .icon-link {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 20px;
                max-width: 200px;
                text-align: center;

                text-decoration: none;

                .gradient-icon {
                    width: 80px;
                    height: 80px;
                    border-radius: 80px;
                    padding-top: 2px;

                    margin-bottom: 10px;

                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    
                    background-color: #ffffff;
                    box-shadow: 0 5px 30px 0 rgba(0,0,0,0.1);

                    .icon {
                        width: 50%;
                    }
                }

                .text {
                    color: #1c252c;
                    font-size: 16px;
                    font-weight: 700;
                }
            }
        }
    }
}
