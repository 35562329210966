.modal-header {
    border-bottom-color: #d4dde3;
    align-items: center;
    padding: 18px 24px 18px 24px;



    .modal-title {
        display: flex;
        align-items: center;
        line-height: auto;
        font-weight: $font-weight-samibold;
        width: 100%;
    }

    .close {
        @extend %close-icon;
        margin: 0 !important;
        padding: 0 !important;
    }
}

.modal-footer {
    border-top-color: #d4dde3;
}
.modal-small {
    width: 30% !important;
}

.modal-medium {
    max-width: 480px !important;
}

.modal-content {
    border: 0;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.02), 0 4px 36px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    .modal-body {
        padding: 12px 24px;
        .modal-footer {
            padding: 10px;
        }
    }
    .modal-footer {
        padding: 12px 24px;
    }
}
.modal-confirm {
    .modal-content {
        .modal-body {
            padding: 40px;
            font-size: 15px;
        }
    }
}

.py-modal__header__title {
    margin: 0;
}

.modal {
    background-color: rgba(16, 50, 101,0.5);
}

.py-modal {

    .modal-content {
        border: 0;
    }


    .modal-body {
        padding: .8rem 1.5rem;
    }

    .modal-footer {
        padding: .8rem 1.5rem;


        // overrides Roboto font from material design
        // .btn,
        // .button,
        // a,
        // button {
        //     font-family: $font-sans-serif !important;
        //     font-weight: $font-weight-bold;
        // }

    }
}


.modal-backdrop {
    background: none !important;

    &.show {
        opacity: .4;
    }
}

.py-modal--large {
    max-width: 820px !important;
    max-width: 100%;
}