// Cards
// Created by Alex

.card-wizard {
    border: 0;

    >.card-body {
        padding: 0;
        border: 0;

        .py-heading--section-title {
            margin-top: 0px;
        }

        >.container-fluid {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

.card-title {
    font-weight: 400;
}

.card-hover {
    box-shadow: 0px 0px 1px rgba($black, .4);
    transition: all .2s ease-in-out;

    &:hover {
        border-color: $primary;
        transform: translateY(-4px);
        box-shadow: 0 0 10px rgba($black, .4);
    }
}



.py-payment-card {
    width: 269px;
    height: 152px;
    padding: 20px;
    position: relative;
    border-radius: 20px;
    font-size: 15px !important;
    font-weight: 400 !important;
}

.py-payment-card--details {
    position: absolute;
    right: 20px;
    bottom: 20px;
    left: 20px;
}

.py-payment-card--number {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white;
    font-size: 1.5rem;
    line-height: 1.7rem;
    // margin-bottom: 1.5rem;
}

.py-payment-card--name-date {
    color: $white;
    position: relative;
    margin-top: 5px;
    overflow: hidden;
    min-height: 25px;
}

.py-payment-card--logo {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0px, -50%);
}

.py-payment-card--bank-details {
    display: flex;

    .py-payment-card--logo {
        position: static;
        background-color: $white;
        min-width: 3rem;
        min-height: 3rem;
        max-width: 3rem;
        max-height: 3rem;
        border-radius: 4px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;

        svg,
        img {
            width: 100%;
            height: 100%;
        }

        .fal {
            &:before {
                font-size: 2rem;
            }
        }
    }

    .py-payment-card--number {
        margin: 0;
    }
}

.py-payment-card--details-name {
    color: $white;
}

.py-box {
    display: block;
    padding: 15px;
    margin-bottom: 12px;
    border-radius: 8px;
    border: 1px solid #b2c2cd;
    background-color: #ffffff;
    .btn.dropdown-toggle.btn-default.btn.btn-secondary {
        padding: 8px 18px 8px 10px;
        &:focus {
            color: $black;
        }
    }

    .btn-default.btn.btn-secondary {
        background: none;
        border-color: #b2c2cd;
    }
}

.py-box--large {
    padding: 16px;
    margin-bottom: 15px;
    &.invoice-insights__content {
        margin-bottom: 35px;
    }
}

.py-box--xlarge {
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 1rem;
}

.py-box--small {
    padding: 8px;
}


.py-box--card {
    padding: 0;

    .py-box__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        border-bottom: 1px solid $border-color;

        .py-box__header-title {
            flex-grow: 1;
            font-size: 1rem;
            font-weight: $font-weight-bold;

            +p,
            +.py-text {
                margin: 0;
            }
            
            &.size-sm {
                font-size: 1.4rem;
            }
        }
    }


    .py-box--content {
        padding: 18px;
    }

    &.py-box--xlarge {
        .py-box__header {
            padding: 1rem 2rem;

            &-title {
                font-size: 22px;
                font-weight: $font-weight-bold;


            }
        }

        .py-box--content {
            padding: 2rem;
        }
    }

    &.py-box--small {
        .py-box__header {
            padding: 8px;
        }

        .py-box--content {
            padding: 8px;
        }
    }


}

.py-box--gray {
    background: #ecf0f3;
    border-radius: 8px;
    border: 0;
}

.py-header--actions {
    .py-box--gray.invoice-status-badge {
        .badge {
            margin-right: 10px !important;
        }
    }
}


.py-editable-user-list {
    .editable-user-list-item {
        .py-box {
            border-radius: 0px;
            border: 1px solid #b2c2cd;
            padding: 24px 9px;
            margin-top: -1px;
            .py-text {
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }
        &:first-child {                    
            .py-box {
                border-radius: 8px 8px 0px 0px;
            }
        }
        &:last-child {                    
            .py-box {
                border-radius: 0px 0px 8px 8px;
            }
        }
    }
}

.cardSave-check {
    .form-check-input {
        margin-top: 6px;
        margin-right: 10px;
    }
}

.card {
    .card-balance {
        font-size: 24px;
        font-weight: 700;
    }
}