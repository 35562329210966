// Custom Variables
// Created by: Alex


$alert-bg-level: -10;
$alert-border-level: -9;
$alert-color-level: 4;


$white : #FFFFFF;
$gray : #F6F8F9 !default;
$gray-100 : #ecf0f3 !default;
$gray-200 : #dbe3e6 !default;
$gray-300 : #E4E4E4 !default;
$gray-400 : #4d6575 !default;
$gray-500 : #9EB4BD !default;
$gray-600 : rgb(32, 44, 48) !default;
$gray-700 : #86A5AC !default;
$gray-800 : hsl(192, 19%, 55%) !default;

// Black Shades
$black : #1c252c !default;
$black-dark : #0d0d0d  !default;
$black-light : #808080 !default;
$black-lighter : #999999 !default;
$black-lightest : #b3b3b3 !default;

// Blue shades
$blue : #136acd !default;
$blue-dark : #0f529f !default;
$blue-light : #1f7eea !default;
$blue-lighter : #81cefe !default;
$blue-lightest : #b3e1fe !default;

// Teal Shades
$teal : #1E7889 !default;
$teal-dark : #155460 !default;
$teal-light : #8DC0C6 !default;
$teal-lighter : #C6E1E4 !default;
$teal-lightest : #ECF5F6 !default;

// Green Shades
$green : #04B84A !default;
$green-dark : #038736 !default;
$green-light : #73D08B !default;
$green-lighter : #B9E8C5 !default;
$green-lightest : #E8F7EC !default;

$purple : #6E0DEA !default;
$pink : #efa7bf !default;


// Red Shades
$red : #e20334 !default;
$red-dark : #fc1e4f !default;
$red-light : #fc4f75 !default;
$red-lighter : #fd819c !default;
$red-lightest : #feb4c4 !default;

// Orange shades
$orange : #E7CE9C !default;
$orange-dark : #DDBA73 !default;
$orange-light : #F1E2C4 !default;
$orange-lighter : #F8F0E1 !default;
$orange-lightest : #FDFAF5 !default;

// Yellow Shades
$yellow : #E7CE9C !default;
$yellow-dark : #DDBA73 !default;
$yellow-light : #F1E2C4 !default;
$yellow-lighter : #F8F0E1 !default;
$yellow-lightest : #FDFAF5 !default;


$cyan : #0dc6e3 !default;


// Color Theme
$primary: $blue !default;
$primary-dark: $blue-dark !default;
$primary-light: $blue-light !default;
$primary-lighter: $blue-lighter !default;
$primary-lightest: $blue-lightest !default;

$danger: #d74242;

$secondary : #5E5855 !default;
$secondary-dark: #433F3D !default;
$secondary-light: #9E9B99 !default;
$secondary-lighter: #CFCDCC !default;
$secondary-lightest: #EFEEEE !default;

$info: $teal !default;

// $warning: $orange !default;
$link-color: $primary !default;


// Input
// $input-color: $gray0;
$border-width: 1px;
$input-color: $black !default;
$input-border-width: 1px;

$body-color: $black !default;

// border
$border-color: #b2c2cd !default;
$table-hover-bg: $gray !default;
$body-bg: #ffffff;



$materialIcons: 'Material Icons';


// Buttons
$btn-bg-color: #ecf0f3;
$btn-text-color: $gray-700;


$font-family-sans-serif: 'Payyit Sans',-apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
// $font-family-sans-serif: 'peym eina',-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
$base-font-family: $font-family-sans-serif !default;

$font-size-base:              1.15rem !default;

$line-height-base: 1.35 !default;

// $headings-font-family: "Gordita";

$headings-font-weight: 700 !default;
$font-weight-bold: 700 !default;
$font-weight-samibold: 600 !default;
$font-weight-medium: 500 !default;
$font-weight-normal: 400 !default;


// Radius
$border-radius: 4px;

// $box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
// $box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
// $box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;

$box-shadow: 0 .2rem .5rem rgba($black, 0.3) !default;

// icons 

%dropdown-caret {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid $border-color;
  position: absolute;
  right: 1rem;
  top: 18px;
  pointer-events: none;
}

%close-icon {
  position: relative;
  padding: 0;
  margin: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  opacity: .5;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 11px;
    top: 0;
    height: 24px;
    width: 2px;
    background-color: $gray-600;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  &:hover {
    opacity: 1;
  }

  span {
    display: none;
  }
}