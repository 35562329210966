.dis-table {
    display: table !important;
}

.react-datepicker-wrapper {
    width: auto;
}

.date-frm {
    padding-right: 0px;
}

.date-to {
    padding-left: 0px;
}

.select-cust {
    padding-right: 0px;
}

.back-white {
    background-color: #fff !important;
}

.business-logo {
    width: 120px;
}

.timeframe strong {
    font-size: 14px !important;
    // font-weight: 600 !important;
    text-transform: uppercase !important;
    color: #4d6575;
}

@media (max-width: 768px) {
    .date-frm {
        padding-right: 15px;
    }

    .date-to {
        padding-left: 15px;
    }

    .select-cust {
        padding-right: 15px;
        padding-left: 0px;
    }
}

.statement {
    .header {
        h4 {
            font-size: 33px;
            line-height: 40px;
            font-weight: $font-weight-bold;
        }
    }
}

.statement-preview {
    .header {
        box-shadow: 0 10px 6px -6px #bbb;
    }
}

.display-block {
    display: block;
}


.customerDetails {
    .name {
        font-size: 16px;
        line-height: 30px;
        margin: 0px;
    }

    .detail {
        font-size: 15px;
        line-height: 20px;
        margin: 0px;
    }
}


.customer-statements--filter__container {
    .py-box--content {
        display: flex;
        align-items: flex-start;
    }

    .customer-statements--filter__content {
        flex-grow: 1;

        .py-form-field--inline .py-form-field__element .Select {
            min-width: 220px;
        }

        .py-form-field {
            min-width: 235px;
            width: 100%;
        }

        .py-form-field__label {
            flex: 1;
            min-width: 190px;
        }
    }

    .customer-statements--filter__range {
        display: flex;
        margin-left: 2.5rem;

        .react-datepicker-wrapper {
            width: 180px;
        }

    }
}

.py-action-bar {
    margin-bottom: 24px;
}

.single-statement {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    max-width: 820px;
    margin: 0px auto;
    overflow: auto;
    margin-bottom: 30px;
}

.account-statement-view {
    width: 820px;
    min-height: 1024px;
    margin: 0 auto;
    padding: 30px;
    background-color: #ffffff;
    color: #1c252c;

    .table {
        color: #1c252c !important;
    }

    .py-heading--title {
        color: #1c252c !important;
    }

    .py-text--block-label {
        text-align: center;
        text-transform: uppercase;
        background-color: #EEE;
        color: rgba($black, .8);
        line-height: 40px;
        font-weight: 500;
        font-size: 14px;
        margin: 0 0 20px;
    }

    h3 {
        line-height: 30px;
        font-size: 16px;
        margin: 0;
    }

    .statement-customer-details {
        font-size: 15px;

        p {
            margin: 0;
        }
    }
}

.account-statement__summary {
    tr {
        border: 0;

        td {
            padding: 0;
            border: 0;
            max-width: 150px;
            overflow-wrap: break-word;

            &:last-child {
                padding-left: 15px;
            }
        }
    }
}

.statement-invoice-table {

    thead,
    tbody {
        tr {
            border: 0;
            line-height: 34px;

            &:last-child {
                border: 0;
            }
        }
    }

    th,
    td {
        padding: 0;
        font-weight: normal;
        border: 0;
    }


}

.row-amount-due {
    td {

        h4 {
            font-weight: 300;
            color: #444444;
        }

        h3 {
            font-weight: 500;
        }

        h2 {
            font-weight: normal;
        }
    }
}