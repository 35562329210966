.center_name
{
    padding: 12px;
    align-items: center;
}
#link1
{
    // width: calc(15% - 25px);
    font-weight: 500 !important;
    // margin-left: 100px;
    text-align: right;
}

.mg-lft-40{
    margin-left: 40px !important;
}

.busnsCur{
    padding-left: 20px;
    margin-right: 10px;
}

input.feild-height.readOnly.form-control {
    background: #fff !important;
    border: none;
    font-weight: 600;
    text-align: left;
    padding: 0;
    font-size: 19px !important;
}

#headinginr
{
    margin-left: 143px;
}
#btn
{
    margin-left: 333px;
}
#btn2
{
    padding: 10px 30px;
    border: 2px solid;
    border-radius: 50px;
    border-style: none;
}
#btn2:hover
{
    background: red;
    color: white;
}
.no-mg{
    margin: 0px !important;
}
.clr-mute{
    color: #cacaca8f !important;
}
.pd-rt-20{
    padding-right: 20px !important;
}

.businesseditform {
    .Select {
        .Select-menu-outer {
            margin-top: 0px;
        }
    }
}

.full-child-control {
    .form-control {
        max-width: 100% !important;
    }
}