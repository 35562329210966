// Alex

:root {
    font-size: 14px;
}

svg:not(:root) {
    overflow: hidden;
}

.br-left {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.br-right {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

a {

    &:not(:disabled),
    &:not([class*="disabled"]),
    &:not([class*="is-disabled"]) {
        cursor: pointer;
    }

    &:hover {
        text-decoration: underline;
    }
}

[type="file"] {
    font-size: initial;
    padding: initial;
    line-height: initial;
    box-shadow: none;
    background: initial;
    border: initial;
}

// Irfan
.py-table__hover {
    tbody tr:hover {
        background: #e6f1ff;
    }
}

.py-status-page {
    .py-box {
        border: 0;
    }
}


hr {
    border-top-color: $border-color !important;
    width: 100%;
}

.spinner-border {
    width: 60px;
    height: 60px;
}

.widget-wrapper {
    .spinner-border {
        min-width: 60px;
        min-height: 60px;
    }
}


.error404Wrapper {
    text-align: center;
    padding: 50px 0;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .error-logo {
        margin-bottom: 50px;
    }

    .remove-card-image {
        margin: -50px 0;
    }

    .error-image {
        display: inline-block;
        max-width: 455px;
        width: 100%;

        img {
            max-width: 100%;
        }
    }

    .py-list--plain--vertical {
        list-style: none;
        margin: 0px;
        padding: 0px;

        li {
            display: inline-block;
            margin: 10px;
        }
    }

    .py-heading--title {
        font-size: 33px;
    }

    .py-text {
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: #1c252c;
    }

    .py-text--fine-print {
        color: #4d6575;
        font-size: 14px;
        line-height: 22px;
    }






    .box-shadow--py-page {
        box-shadow: 0px 0px 0px 4px #ccc;
    }




    .color-6 {
        color: #f4f8fb !important;
    }

    .errorRow {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .center-xy {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate3d(-50%, -50%, 0);
        transform: translate3d(-50%, -50%, 0);
    }
}

.height-100 {
    min-height: 100vh;
}

@media (min-width: 576px) {
    .center-xy-sm {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate3d(-50%, -50%, 0);
        transform: translate3d(-50%, -50%, 0);
    }
}

@media (min-width: 768px) {
    .center-xy-md {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate3d(-50%, -50%, 0);
        transform: translate3d(-50%, -50%, 0);
    }
}

@media (min-width: 992px) {
    .center-xy-lg {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate3d(-50%, -50%, 0);
        transform: translate3d(-50%, -50%, 0);
    }
}

@media (min-width: 1200px) {
    .center-xy-xl {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate3d(-50%, -50%, 0);
        transform: translate3d(-50%, -50%, 0);
    }
}

.templateCalculation {
    display: block;
    width: 100%;
    float: right;
    margin: 20px auto;

    table {
        display: block;
        float: right;
        margin-right: 30px;
    }

    tr td {
        border: none;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 0px;
        padding-left: 0px;
        text-align: right;
    }


    .divider {
        display: block;
        height: 0;
        padding: 0;
        border: none;
        border-bottom: 1px solid #d4dde3;
        overflow: hidden;

        // margin: 5px auto;
    }

    .grandDivider {
        border-bottom: 3px solid #d4dde3;
    }
}

/* btn css*/

.btn-left-rounded {
    border-radius: 25px 0px 0px 25px;
}

.btn-right-rounded {
    border-radius: 0px 25px 25px 0px !important;
}

.btn-dropdown-rounded {
    width: 35px !important;
    min-width: 35px !important;
    padding: 9px 10px 5px 4px !important;
}

.btn-3 {
    color: #fff;
    background-color: $primary;
    border-color: $primary;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: $font-weight-bold;
}

.mg-bt-0 {
    margin-bottom: 0px !important;
}

.pd-r-l-15 {
    padding: 0 35px !important;
}

.pd-r-30 {
    padding-right: 30px;
}

.mg-t-30 {
    margin-top: 30px !important;
}

.mg-t-2 {
    margin-top: 2rem;
}

.mg-15 {
    margin: 15px !important;
}

.mr-b-20 {
    margin-bottom: 20px
}

.account-info {
    .popover {
        min-width: 500px;
    }
}

//Jalaj

.confirmModal-wrppr {
    .confirmModal-body {
        padding: 15px !important;
    }

    .confirmModal-footer {
        .btn.cancel {
            background: none;

            span {
                color: #136acd !important;
            }
        }
    }
}

.yourCard {
    background: linear-gradient(to bottom right, #000428, #004e92) !important;
    border-radius: 5px !important;
    height: 136px !important;
    width: 240px !important;
    word-break: break-all;
    padding-left: 10px !important;
    // padding-top: 17% !important;
}

.cardName {
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 80%;
    display: block;
}

.expandHeader {
    line-height: 1.4 !important;
    font-weight: bold !important;
    font-size: 23px !important;
}

.paymentsArrow {
    padding-top: 85px;
}

.border-gray {
    border: 1px solid #b2c2cd !important;
    color: #4d6575 !important;
}

.launchRocket {
    font-size: 23px !important;
    padding-right: 14px;
    padding-left: 5px;
}

.salesIcon {
    font-size: 20px;
    padding-right: 8px;
}

.balanceIcon {
    font-size: 20px;
    color: #b2c2cd;

    &:hover {
        color: #4d6575;
    }
}



.universityIcon {
    font-size: 18px;
    padding-right: 5px;
}

.walletIcon {
    font-size: 18px;
    margin-right: 0 !important;
}

.extra-Link {
    padding: 2px 5px;
}

.extraLink {
    font-size: 15px;
}


.invoice-tbl {
    tr {
        th {
            padding-left: 7px !important;
        }
    }
}

.btn-gray-accent {
    background: #d7e0ea;
    color: #4d6575;
    border: none;
}

.mg-r-l-5 {
    margin: 0 8px 0px 0px;
}

.pd-r-5 {
    padding-right: 5px;
}

i.fa-chevron-up.open {
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
}

// @TODO: Move this is to wherever relevant, should not be present at global level
i.fa-chevron-up {
    //font-size: 10px;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    //color: #b2c2cd;
}

.toggleStatus {
    width: 10px;
    height: 10px;
    border: 3px solid white;
    margin-right: 8px;
    border-radius: 50%;
    background: #23c770;
}

.launch {
    margin-bottom: 6px !important;
}

.selected {
    font-weight: bold !important;
}

.pd-12 {
    padding: 12px !important;
}

.dropLeft {
    transform: translate3d(-148px, 40px, 0px) !important;
}

.disabledMenu {
    font-weight: bold !important;
    line-height: 20px;
    white-space: nowrap;
    font-size: 16px !important;
}

.invoice-preview__body {

    th.thAmount {
        width: 24%;
    }

    th.thPrice {
        width: 18%;
    }

    th.thQuantity {
        width: 18%;
        text-align: center;
    }

    th.thItems {
        width: 40%;
    }


    .py-divider {
        display: block;
        height: 0;
        padding: 0;
        border: none;
        border-bottom: 1px solid $gray;
        overflow: hidden;
        margin: 24px auto;
    }

    .py-divider {
        margin: 12px 0 !important;
    }



    .py-table__header {

        .py-table__row {
            border-bottom: 3px solid $border-color;

            th:nth-child(1) {
                padding-left: 30px
            }

            th:nth-child(4) {
                padding-right: 30px
            }
        }
    }

    .py-table__body {
        .py-table__row {
            td:nth-child(1) {
                padding-left: 30px;
                max-width: 300px;
                overflow: hidden;
                word-break: break-all;
            }

            td:nth-child(4) {
                padding-right: 30px
            }
        }
    }

    .temlpateTotalLabel {
        text-align: right;
        width: 100%;
        padding-right: 35px;
    }

    .temlpateTotalAmount {
        word-break: break-all;
        min-width: 125px;
    }
}

.placeHolderFont::-webkit-input-placeholder {
    font-size: 13px !important;
}

.amountTable-wrapper {
    .amountTable-table {
        width: 100%;

        tr {
            td {
                text-align: right;
                padding-right: 0;
                padding-left: 0;
                font-size: 14px;
            }

            td:nth-child(2) {
                min-width: 125px;
                width: 125px;
                padding-left: 30px;
                line-height: 26px;
            }
        }
    }
}

.mb-0 {
    margin-bottom: 0px;
}

.text-muted {
    color: #8C959A !important;
}

.spinner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 100px auto;


    .spinner-box {
        position: relative;
        display: inline-block;
        z-index: 999;

        .spinner-border {
            width: 150px;
            height: 150px;
        }

        &:before {
            content: "";
            position: absolute;
            left: 53%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 50%;
            height: 50%;
            display: block;
            background: url("https://peymynt-beta.s3.us-west-1.amazonaws.com/static/payyit/icon-logo.png") no-repeat scroll center center/contain;
        }
    }

}

.invoice-preview__body {
    .matchPd {
        // padding-left: 29px !important;
        padding: 0.75rem 0 29px 0.55rem;
    }

    .pdfTable-total {
        max-width: 200px;
        word-break: break-all;
    }

    .pdfTable-price {
        max-width: 200px;
        word-break: break-all;
    }

    .pdfTable-quantity {
        max-width: 100px;
        text-align: center;
    }

    .pdfTable-item {
        max-width: 300px;
        word-break: break-all;
    }

    .invoice-tbl {
        td:nth-child(5) {
            padding-right: 12px;
        }
    }
}

.readonly_footer {
    text-align: center;
    padding: 50px 0;


    @media screen and (max-width: 768px) {
        .copyright {
            display: block;
        }
    }

    // .py-logo--powered-by {
    //   // border: 1px solid $border-color;
    //   display: inline-block;
    //   padding: 6px 17px;
    //   border-radius: 30px;
    //   // cursor: pointer;
    //   font-size: 12px;

    //   &:hover {
    //     border: 1px solid $primary;
    //   }
    // }

    .track-and-get-started {
        display: block;
        padding: 15px 0;

        a {
            font-weight: 600;
        }
    }

    .footer_menu {

        a {
            text-decoration: none;
            font-weight: 600;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.invoice-preview__body {
    .matchPd {
        // padding-left: 29px !important;
        padding: 0.75rem 0 29px 0.55rem;
    }

    .pdfTable-total {
        max-width: 200px;
        word-break: break-all;
    }

    .pdfTable-price {
        max-width: 200px;
        word-break: break-all;
    }

    .pdfTable-quantity {
        max-width: 100px;
        text-align: center;
    }

    .pdfTable-item {
        max-width: 300px;
        word-break: break-all;
    }

    .invoice-tbl {
        td:nth-child(5) {
            padding-right: 12px;
        }
    }
}

.spinnerWrapper {
    text-align: center;
    padding-top: 50px;
}

.reactStrap-design {
    color: #41494f;
    border-color: #b2c2cd !important;
    border-radius: .25rem;
    padding: .375rem .75rem;
    width: 100%;
    font-size: 13px;
}

.noBorder {
    border: none !important;
    box-shadow: none !important;
    outline: none;

    &:hover,
    &:focus {
        border: none !important;
        box-shadow: none !important;
        outline: none;
    }
}

.modalBtn {
    padding: 4px 16px !important;
    font-size: 14px !important;
    height: 30px !important;
    float: right;
    color: #fff !important;
    background-color: #136acd !important;
    border-color: #136acd !important;
}

.color-red {
    color: red !important;
}

input.err {
    border-color: red !important;
}

.err {
    input.form-control {
        border-color: red !important;
    }
}

.btnLoader {
    height: 20px;
    width: 20px;
    color: #fff !important;
}

.step-msg.message.warning {
    background: #fcfbe3;
    border-color: #f6f2ac;
    color: #7c760e;
}

.step-msg.message {
    border-radius: 8px 8px 0 0;
    border: 1px solid #b2c2cd;
    display: flex;
    align-items: center;
    justify-content: center;

    span.symbol {
        font-weight: bold !important;
        width: 40px;
        height: 20px;
        background: transparent;
        border: 2px solid #f6f2ac;
        color: #7c760e;
        text-align: center;
        display: inline-block;
        border-radius: 50%;
        line-height: 17px;
        margin: 7px 8px 7px 7px;
    }

    span.text {
        padding: 8px 0 8px 8px;
        font-size: 14px;
        line-height: 18px;
    }
}

.colorGray {
    color: #687577 !important;
}

.datePickerWrapper {
    .react-datepicker__header {
        background: #fff;
        border-bottom-color: transparent;
    }

    .calendar-custome-days {
        position: relative;
        position: relative;
        width: 370px;
        height: 280px;
        padding: 10px 20px;

        ul {
            position: absolute;
            padding: 21px;
            background: #fff;
            border-left: 1px solid #d4dde3;
            list-style: none;
            right: 0;
            margin-bottom: 0 !important;
            height: 255px;

            li {
                padding: 5px 0;
                font-size: 14px;
            }
        }
    }
}

.addNew-dropDown {
    position: fixed;
    // bottom: 0;
}

.color-muted {
    color: #4d6575;
}

.primaryAction {
    position: relative;
    top: 5px;
    right: 5px;
    font-weight: $font-weight-bold;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}

.modal-dialog {
    min-width: 320px !important;
    max-width: 640px !important;
}

.modal-md {
    min-width: 320px !important;
    max-width: 500px !important;
}

.modal-dialog.modalSweet-alert {
    min-width: 320px !important;
    max-width: 640px !important;

    .sweetAlert-modal-content {
        margin: 0 0;

        .sweetAlert-modal-success__check {
            display: flex;
            justify-content: center;
        }

        .sweetAlert-modal__heading {
            text-align: center;

            .sweetAlert-modal__heading-title {
                font-size: 23px;
                line-height: 1.4;
                font-weight: 400;
                color: #718fa2;
            }
        }
    }
}

.sweetAlert-modal--success-icon {
    width: 120px;
    height: 120px;
    display: inline-block;
    fill: none;
    stroke: #23c770;
    stroke-miterlimit: 10;
    stroke-width: 6px;
    stroke-linejoin: round;
    stroke-linecap: round;
}

.sweetAlert-modal--success-icon.bounce {
    -webkit-animation: 0.3s checkmarkBounce;
    animation: 0.3s checkmarkBounce;
}

.btn-xs {
    font-size: 13px;
    padding: 4px 25px;
}



// [class*="py-"] {
//   outline: thin solid green;
// }

// :not([class*="py-"]) {
//   outline: thin solid red;
// }



.form-control::placeholder {
    font-style: normal !important;
    color: rgba(40, 21, 64, 0.3);
}

.py-close {
    img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
    }
}

.inline-form-control {
    color: #41494f;
    border: 1px solid #b2c2cd !important;
    border-radius: .25rem;
    padding: 5px .75rem;
    width: 100%;
    font-size: 16px;
}

.bullet-divider {
    margin: 0 8px;
    font-weight: 700;
}

.status-online {
    color: #04B84A;
}

.status-ON {
    color: #029eb6;
}

.status-OFF {
    color: #d02c2c;
}

.devider-circle {
    display: inline-block;
    height: 8px;
    border-radius: 50%;
    width: 8px;
    margin-right: 2px;
    margin-bottom: 1px;
}

.devider-circle.OFF {
    background: #d02c2c;
}

.devider-circle.online {
    background: #04B84A;
}

.devider-circle.ON {
    background: #029eb6;
}

.public-preview-page {
    .btn.btn-primary.btn-block {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.btn {
    .fa-lock {
        display: inline-block;
    }

    .spinner-border {
        vertical-align: middle;
        width: 15px;
        height: 15px;
    }
}

.PublicPayout-body-10 {
    .nav-item {
        text-align: center;
        font-size: 19px;
    }
}

.external-link {
    background-image: url('/assets/icon--external-link.svg');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 11px 11px;
    padding-right: 15px;
    display: inline-block;
}

.react-bs-table-sizePerPage-dropdown.dropdown {
    #pageDropDown {
        min-width: 74px;
    }

    .dropdown-menu {
        min-width: 5.3rem;
        margin: 6px 0px 0px;
        padding: 0px;
        border-radius: 0px 0px 5px 5px;

        .dropdown-item {
            text-align: center;
            min-width: 72px;
            padding: 5px;

            a {
                display: block;
                width: 100%;
                line-height: 28px;
                min-width: 58px;
            }
        }
    }
}

.csvPreviewAlert {
    display: block;
    padding: 5px;
    margin-bottom: 2px;
    border-radius: 8px;
    border: 1px solid #b2c2cd;
    background-color: #fff;
    background: #ecf0f3;
    box-shadow: none;
    border: none;
    vertical-align: middle;
    align-items: center;

    span {
        vertical-align: middle;
        align-items: center;

        .pe-7s-info {
            vertical-align: middle;
        }
    }
}

.info-text {
    border-bottom: 2px dotted;
    border-color: inherit;
}

.endPicker {
    .react-datepicker-popper {
        left: -90px !important;
    }
}

.chrome-picker {
    width: 100% !important;
}

.capitalize-text {
    text-transform: capitalize;
}

.font-bold {
    font-weight: $font-weight-bold;
}

.disabled-option {
    font-style: italic !important;
    color: rgba(47, 53, 70, 0.3);
}

.word-break {
    word-break: break-word;
}

.dropdown-menu-left {
    left: -100px !important
}

.dropdown-menu-center {
    left: 50% !important;
    right: auto !important;
    top: 100% !important;
    bottom: auto;
    transform: translateX(-50%) !important;

    &[x-placement^="bottom"] {
        top: 100% !important;
        bottom: auto;
    }
    &[x-placement^="top"] {
        bottom: 100% !important;
        top: auto;
    }
}

//jalaj end
.nav-footer {
    .nav-item {
        vertical-align: middle;
        text-align: center;
    }

    .far.fa-question-circle {
        padding-right: 2px;
    }

    .fa-circle.off {
        color: #8aa2b2;
        font-size: 11px;
        border: 3px solid #ecf0f3;
        border-radius: 50%;
        padding: 0;
        margin-right: 3px;
    }

    .fa-circle.on {
        color: green;
        font-size: 11px;
        border: 3px solid #ecf0f3;
        border-radius: 50%;
        padding: 0;
        margin-right: 3px;
    }

    button {
        text-align: center;
        // margin: 0;
    }
}

.statusSuccess {
    background-color: #adf0cc;
    color: #14713f;
    font-size: 13px;
    line-height: 16px;
    font-weight: bold;
    display: inline-block;
    margin: 2px 0;
    padding: 4px 12px 4px 14px;
    border-radius: 40px 12px 12px 40px;
    white-space: nowrap;
    text-transform: capitalize;
}

.fw-b {
    font-weight: bold !important;
}

.btn {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    text-decoration: none;
    font-weight: 400;
    text-transform: none;
    // padding: 0.5rem 1.25rem;
    font-size: 16px;
    padding: 12px 30px;
    line-height: 1em;
    text-align: center;
    vertical-align: middle;
    white-space: normal;
    cursor: pointer;
}

.btn-outline-2 {
    color: #646f79;
    background-color: transparent;
    background-image: none;
    border-color: #646f79;
}

.archiveConfrmWrp .archiveCnfrmMsg {
    padding: 20px 50px;
    background: #fcfbe3;
    border-color: #f6f2ac;
    color: #7c760e !important;
    border: 1px solid #f6f2ac;
    margin-bottom: 20px;
}

.archiveConfrmWrp .archiveCnfrmMsg h6 {
    font-weight: $font-weight-bold;
    margin-bottom: 16px;
    color: #7c760e !important;
}

.archiveConfrmWrp a {
    color: $primary !important;
    padding: 5px;
    font-weight: $font-weight-bold;

    &:hover {
        text-decoration: underline;
    }
}

.brand-facebook {
    color: #fff;
    background-color: #3b5998;
    border-color: #3b5998;
}

.brand-facebook:hover {
    color: #fff;
    background-color: #30497c;
    border-color: #2d4373;
}

.brand-facebook:focus,
.brand-facebook.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5);
}

.brand-facebook.disabled,
.brand-facebook:disabled {
    color: #fff;
    background-color: #3b5998;
    border-color: #3b5998;
}

.brand-facebook:not(:disabled):not(.disabled):active,
.brand-facebook:not(:disabled):not(.disabled).active,
.show>.brand-facebook.dropdown-toggle {
    color: #fff;
    background-color: #2d4373;
    border-color: #293e6a;
}

.brand-facebook:not(:disabled):not(.disabled):active:focus,
.brand-facebook:not(:disabled):not(.disabled).active:focus,
.show>.brand-facebook.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5);
}

.brand-facebook:hover {
    -webkit-box-shadow: 0 1px 10px rgba(59, 89, 152, 0.4) !important;
    box-shadow: 0 1px 10px rgba(59, 89, 152, 0.4) !important;
}

.brand-google {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39;
}

.brand-google:hover {
    color: #fff;
    background-color: #cd3623;
    border-color: #c23321;
}

.brand-google:focus,
.brand-google.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(221, 75, 57, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(221, 75, 57, 0.5);
}

.brand-google.disabled,
.brand-google:disabled {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39;
}

.brand-google:not(:disabled):not(.disabled):active,
.brand-google:not(:disabled):not(.disabled).active,
.show>.brand-google.dropdown-toggle {
    color: #fff;
    background-color: #c23321;
    border-color: #b7301f;
}

.brand-google:not(:disabled):not(.disabled):active:focus,
.brand-google:not(:disabled):not(.disabled).active:focus,
.show>.brand-google.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(221, 75, 57, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(221, 75, 57, 0.5);
}

.brand-google:hover {
    -webkit-box-shadow: 0 1px 10px rgba(221, 75, 57, 0.4) !important;
    box-shadow: 0 1px 10px rgba(221, 75, 57, 0.4) !important;
}

.brand-gplus {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39;
}

.brand-gplus:hover {
    color: #fff;
    background-color: #cd3623;
    border-color: #c23321;
}

.brand-gplus:focus,
.brand-gplus.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(221, 75, 57, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(221, 75, 57, 0.5);
}

.brand-gplus.disabled,
.brand-gplus:disabled {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39;
}

.brand-gplus:not(:disabled):not(.disabled):active,
.brand-gplus:not(:disabled):not(.disabled).active,
.show>.brand-gplus.dropdown-toggle {
    color: #fff;
    background-color: #c23321;
    border-color: #b7301f;
}

.brand-gplus:not(:disabled):not(.disabled):active:focus,
.brand-gplus:not(:disabled):not(.disabled).active:focus,
.show>.brand-gplus.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(221, 75, 57, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(221, 75, 57, 0.5);
}

.brand-gplus:hover {
    -webkit-box-shadow: 0 1px 10px rgba(221, 75, 57, 0.4) !important;
    box-shadow: 0 1px 10px rgba(221, 75, 57, 0.4) !important;
}

.brand-twitter {
    color: #fff;
    background-color: #00aced;
    border-color: #00aced;
}

.brand-twitter:hover {
    color: #fff;
    background-color: #0090c7;
    border-color: #0087ba;
}

.brand-twitter:focus,
.brand-twitter.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 172, 237, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(0, 172, 237, 0.5);
}

.brand-twitter.disabled,
.brand-twitter:disabled {
    color: #fff;
    background-color: #00aced;
    border-color: #00aced;
}

.brand-twitter:not(:disabled):not(.disabled):active,
.brand-twitter:not(:disabled):not(.disabled).active,
.show>.brand-twitter.dropdown-toggle {
    color: #fff;
    background-color: #0087ba;
    border-color: #007ead;
}

.brand-twitter:not(:disabled):not(.disabled):active:focus,
.brand-twitter:not(:disabled):not(.disabled).active:focus,
.show>.brand-twitter.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 172, 237, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(0, 172, 237, 0.5);
}

.brand-twitter:hover {
    -webkit-box-shadow: 0 1px 10px rgba(0, 172, 237, 0.4) !important;
    box-shadow: 0 1px 10px rgba(0, 172, 237, 0.4) !important;
}

.brand-linkedin {
    color: #fff;
    background-color: #007bb6;
    border-color: #007bb6;
}

.brand-linkedin:hover {
    color: #fff;
    background-color: #006190;
    border-color: #005983;
}

.brand-linkedin:focus,
.brand-linkedin.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 182, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 182, 0.5);
}

.brand-linkedin.disabled,
.brand-linkedin:disabled {
    color: #fff;
    background-color: #007bb6;
    border-color: #007bb6;
}

.brand-linkedin:not(:disabled):not(.disabled):active,
.brand-linkedin:not(:disabled):not(.disabled).active,
.show>.brand-linkedin.dropdown-toggle {
    color: #fff;
    background-color: #005983;
    border-color: #005076;
}

.brand-linkedin:not(:disabled):not(.disabled):active:focus,
.brand-linkedin:not(:disabled):not(.disabled).active:focus,
.show>.brand-linkedin.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 182, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 182, 0.5);
}

.brand-linkedin:hover {
    -webkit-box-shadow: 0 1px 10px rgba(0, 123, 182, 0.4) !important;
    box-shadow: 0 1px 10px rgba(0, 123, 182, 0.4) !important;
}

.btn-accent {
    height: 40px;
    min-width: 100px;
}

.btn-transparent {
    color: #4d6575 !important;
    background-color: Transparent !important;
    border-color: #4d6575 !important;

    &:hover {
        color: $blue-light !important;
        border-color: $blue-light !important;
    }
}

.ajax-button {
    position: relative;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: contents;
}

.ajax-button .btn-status {
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    position: absolute;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
}

.btn-round {
    border-radius: 20px;
}

.btn-gray {
    height: 40px;
    min-width: 100px;
    color: #4d6575 !important;
    background: #ecf0f3 !important;
    border: 1px solid transparent !important;

    &:hover {
        background-color: #e0f4fb !important;
        color: #136acd !important;
        border: 1px solid transparent !important;
    }
}

.dropdown-btn {
    button {
        border: 1px solid transparent;
        color: #fff;
        font-size: 16px;
        font-weight: 600;

        text-align: center;
        min-width: 100px;
        border-radius: 500px;
        margin: 0;
        display: inline-block;
        box-sizing: border-box;
        vertical-align: middle;
        outline: 0;
        cursor: pointer;
        text-decoration: none;
        -webkit-appearance: none;
    }

    .drop-btn-accent {
        background-color: #136acd !important;
    }

    .drop-btn-gray {
        color: #4d6575 !important;
        background-color: #ecf0f3 !important;

        &:hover,
        &:active,
        &:focus {
            background-color: #e0f4fb;
            color: #136acd;
            border: 1px solid transparent;
        }
    }
}

.dropdown-menu-item {
    min-width: 15rem !important;
}

.dropdown-menu-item-export {
    min-width: 20px !important;
}

.btn-rounded.btn-border {
    background-color: #fff !important;
    color: #4d6575 !important;
    font-size: 14px !important;

    &:hover {
        background-color: #d74242 !important;
        border-color: #d74242 !important;
        color: #ffff !important;
    }
}

.btn-cirle {
    max-width: 35px;
    max-height: 35px;
    background-color: #fff !important;
    color: #4d6575 !important;
    border-radius: 50% !important;
    padding: 8px 12px !important;
    border-color: #b2c2cd !important;
}

/*btn css end*/

.comingSoon {
    text-align: center;
    margin-top: 50px !important;
    // background: #e0f4fb;
    // border-color: #bce7f6;
    // color: #147494;
    width: calc(100% - (2 * 2px));
    border-radius: 4px;
    border: 2px solid transparent;
    // margin-bottom: 16px;
}

.help-block {
    color: #bbb;
    font-size: 11px;
    font-weight: normal;
    line-height: 14px;
    display: block;
    clear: both;
}

.checkbox-custom {
    padding-left: 0px;

    .form-check-input {
        position: relative;
        margin-left: 0;
    }
}

.icon-add {
    display: block;
    padding-top: 4px;

    .icon {
        font-size: 30px;
    }
}

// .col-form-label{font-size: 0.8em;}
// p{font-size: 0.9em;}
// label{font-size: 0.8em;}
.offset-l-24 {
    margin-left: 24%;
}

.modal {

    .modal-add {
        max-width: 640px !important;
        min-width: 320px !important;

        .modal-foo {
            text-align: right;
            /*border-top: 1px solid #ddd;*/
            margin-bottom: 0px;
            padding-top: 0px;
        }

        .modal-body {
            overflow-y: scroll;
            overflow: auto; // height:390px;
            // padding-bottom: 0;
        }
    }
}

.nav-tabs {
    border-bottom-color: $gray-100;

    .nav-link {
        font-size: 16px;
    }
}

.folder-list {
    list-style: none;

    .sub-folder-list {
        list-style: none;
    }
}

.table-layout {
    display: block;
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
    border: 1px solid #b2c2cd;
    background-color: #fff;
}

.table-no-border {
    thead {
        tr {
            th {
                border-top: none;
            }
        }
    }

    tfoot {
        tr {
            td {
                .add-icon {
                    font-size: 14px;
                    display: flex;
                    align-items: center;

                    .icon {
                        font-size: 18px;
                        font-weight: $font-weight-bold;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}

.pt-badge {
    background-color: #718fa2;
    font-size: 11px;
    line-height: 16px;
    font-weight: $font-weight-bold;
    display: inline-block;
    margin: 2px 0;
    padding: 4px 8px;
    border-radius: 3px;
    color: white;
    white-space: nowrap;
    text-transform: capitalize;
}


.badge-gray {
    background-color: #c7d3dc;
    color: #395260;
}

.badge-off {
    background-color: #d1d7d8 !important;
}

/* .default-info{
	background-color: #718fa2 !important;
} */

.modal-confirm {
    max-width: 400px !important;
}

.onboard-form {

    .note-text {
        font-size: 12px;
        margin-bottom: 5px;
    }
}

.invoice-status-badge {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-weight: 400;
    padding: 2px 8px;
    // text-transform: uppercase;
    font-size: 14px;
    border-radius: 40px;
    background: #839299;
}

.Select--single {
    text-align: left;
}

.textarea-height {
    min-height: 200px;
}

.offset-custom {
    margin-left: 23%;
}


.form-group .custom-select {
    padding: 0;
}

.btn-search {
    .input-group-append {
        .btn {
            background-color: #ecf0f3 !important;
            border-color: #b2c2cd !important;
            color: #849194 !important;
            padding: 0px 15px !important;
            font-size: 20px !important;
            line-height: 38px;
            height: 40px;
            display: inline-block;
            border-radius: 0;
        }
    }
}

.btn-close {
    font-size: 20px;
    color: #000;

    .icon {
        font-weight: $font-weight-bold;
    }
    &:hover,
    &:focus {
        outline: none;
        box-shadow: none;
    }
}


.btn-google {
    background-color: #fff !important;
    border: 1px solid #ddd !important;
    box-shadow: none !important;

    >div {
        background: transparent !important;
    }
}

.nav-link {
    cursor: pointer;
}

.nav-tabs {
    .nav-item {
        margin-right: 1rem;
    }

    .nav-link {
        border: 0;
        border-bottom: 4px solid transparent;
        color: $black;
        opacity: .6;

        &:hover {
            border-bottom-color: $gray-700;
            opacity: .8;
        }

        &.active {
            font-weight: $font-weight-bold;
            color: $black;
            border-bottom-color: $primary;
            opacity: 1;
        }

        .badge {
            margin-left: 4px !important;
        }
    }
}

.payment-view__tabs.nav-tabs {
    .nav-link {
        @media (min-width: 769px) {
            &:last-child {
                margin-right: 0px;
            }

            &:first-child {
                margin-left: 0px;
            }
        }

        &.active {
            box-shadow: 0px -4px 4px rgba(28, 37, 44, 0.1);
            position: relative;
            z-index: 0;
        }
    }
}

.badge-circle {
    background: #dcdfe0 !important;
    color: #5c686a;
    padding: 0 6px !important;
    border-radius: 20px !important;
    font-size: 11px !important;
    font-weight: 500 !important;
}

.modal-common {
    .modal-body {
        .col-lg-3 {
            -ms-flex: 0 0 33.333333% !important;
            flex: 0 0 33.333333% !important;
            max-width: 33.333333% !important;
        }

        .col-lg-4 {
            -ms-flex: 0 0 50% !important;
            flex: 0 0 50% !important;
            max-width: 50% !important;
        }

        .col-lg-2 {
            -ms-flex: 0 0 33.333333% !important;
            flex: 0 0 33.333333% !important;
            max-width: 33.333333% !important;
        }

        .row.form-check {
            text-align: right;

            .col-sm-10 {
                -ms-flex: 0 0 100% !important;
                flex: 0 0 100% !important;
                max-width: 100% !important;
                margin-left: 0px !important;
            }
        }

        .offset-3 {
            margin-left: 33%;
        }
    }
}

.heading-subtitle {
    color: #718fa2;
    font-weight: normal;
    font-size: 16px;
    line-height: normal;
}

.estimateNumber {
    padding: 2px 5px !important;
}

// .alert-box {
//   margin-right: 2rem;

//   i {
//     font-size: 24px;
//     vertical-align: middle;
//     margin-right: 5px;
//   }

//   .alert {
//     width: 100%;
//   }
// }

.justify-content-end {
    justify-content: flex-end;
}

tfoot.nvoice-bill-totals tr td {
    border: none;
    padding: .25rem .75rem;
    font-weight: 600;

    &:nth-child(2) {
        text-align: right;
    }
}


.invoice-preview__actions {
    max-width: 822px;
    margin: 14px auto;

    .btn {
        &:not(:last-child) {
            margin-right: 8px !important;
        }
    }

    .badge {
        margin-top: 17px;
    }
}

.rccs__card--front {
    width: 260px;
    height: 150px;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.Select-clear {
    line-height: 2 !important;
}


#mgnbtm {
    margin-bottom: 30px;
}

#or {
    text-align: center;
    text-transform: lowercase;
    margin: 24px auto;
    position: relative;
    display: block;
    overflow: hidden;
}

#or:before {
    display: block;
    height: 0;
    padding: 0;
    border: none;
    border-bottom: 1px solid #d4dde3;
    overflow: hidden;
    content: "";
    position: absolute;
    top: 50%;
    width: 50%;
    left: 0px;
    margin-left: -1em;
}

#or:after {
    display: block;
    height: 0;
    padding: 0;
    border: none;
    border-bottom: 1px solid #d4dde3;
    overflow: hidden;
    content: "";
    position: absolute;
    top: 50%;
    width: 50%;
    right: 0;
    margin-right: -1em;
}

.bleft_top {
    margin-top: 50px;
}

.invo {
    display: block;
    padding: 12px;
    margin-bottom: 12px;
    border-radius: 8px;
    border: 1px solid #b2c2cd;
    background-color: #fff;
}

// .cust-select {

//   .cust-field {
//     border: 1px solid #eee;
//     padding: 5px;

//     input {
//       border: none !important;
//       padding: 0;
//     }

//     div {
//       padding: 3px 5px 3px 5px;
//       margin: 3px 0 3px 0px;
//       position: relative;
//       line-height: 13px;
//       color: #333;
//       cursor: default;
//       border: 1px solid #aaa;
//       display: flex;
//       justify-content: flex-start;
//       border-radius: 2px;
//       background: #e4e4e4;
//       font-size: 14px;

//       i {
//         margin-right: 10px;
//         cursor: pointer;
//       }

//     }
//   }
// }


.initial-color {
    background-color: #7083de;
}

.try-color {
    background-color: darken(#7083de, 40%);
}

// .w-100 {
//     width: 125px !important;
// }

.pdR5 .py-input--xlarge,
.pdL0 .py-input--xlarge {
    width: 200px;
    padding: 12px 16px;
    font-size: 23px !important;
    line-height: 32px !important;
    height: 50px !important;
}

.py-est form label {
    font-size: 14px;
}

.py-est form label~div {
    padding-left: 10px;
}

.Select-clear {
    display: none;
}


/* ---- Select Box ----- */
.select-40 {
    margin: 4px 0;
    max-width: 320px;
}

/* width */
::-webkit-scrollbar {
    width: 7px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba($black, .1);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $border-color;
}


/* New Add Card Modal */
.new_card_modal {
    padding-left: 5%;
    padding-right: 5%;

    .fa-lock {
        margin-right: 5px;
        vertical-align: baseline;
    }
}

.align-items-center {
    justify-content: center;
}

.dropdown-item {
    line-height: 30px;
    min-width: 175px;
}

.text-bold {
    font-weight: 700;
}

.text-semibold {
    font-weight: 600;
}

.text-medium {
    font-weight: 500;
}

.text-regular {
    font-weight: 400;
}

.logo-action {
    width: 130px;
}

.coming-soon-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;

    .count-number {
        padding: 0px;
        width: 100%;
    }
}

.text-link {
    text-decoration: underline;
    cursor: pointer;
    outline: none;

    &:hover {
        text-decoration: none;
    }
}


.ml10 {
    margin-left: 10px;
}

.ml10 {
    margin-left: 10px;
}

.banking-list-button {

    .btn-toggle {
        background: none;
        border: none;
        color: #1c252c;
        width: 30px;
        height: 30px;
        min-height: 30px;
        line-height: 30px;
        padding: 0;
        text-align: center;
    }

    .update-button {
        display: inline-block;

        .btn {
            border: none;
            background: none !important;
            width: inherit !important;
            padding: 0px 10px;
            line-height: 1em;

            &:focus,
            &:hover {
                background: none !important;
                box-shadow: none !important;
                border: none !important;
            }
        }

        .btn-group .dropdown-toggle {
            border: 1px solid #ededed;
            border-radius: 100px !important;
            width: 30px !important;
            height: 30px !important;
            padding: 0px !important;
            background: none !important;
            min-height: 30px;

            &:focus,
            &:hover {
                background-color: #0f529f !important;
                border: 1px solid #0f529f !important;
                color: #ffffff !important;
            }
        }

        .btn-group .dropdown-toggle::after {
            margin-left: 1px !important;
        }
    }
}

.btn-blank {
    border: none !important;
    width: auto !important;
    background: none !important;
    padding: 10px !important;
    min-height: inherit !important;
    box-shadow: none !important;
}

.cross-placeholder {
    position: absolute;
    font-size: 12px;
    color: #cccccc;
    right: 50px;
    top: 10px;
    z-index: 99;

    &.append {
        right: 0;
    }
}

@media (max-width: 768px) {
    body .text-xs-center {
        text-align: center !important;
    }

    body .text-xs-right {
        text-align: right !important;
    }

    body .text-xs-left {
        text-align: left !important;
    }
}

.userback-button,
.userback-button-e {
    display: none !important;
}

.monospace {
    font-variant-numeric: tabular-nums;
    font-family: monospace;
}

@media (min-width: 768px) {

    .container,
    .container-sm,
    .container-md {
        max-width: 100%;
        width: 940px;
    }
}

.benefits-description {
    margin-bottom: 15px;
    border-radius: 8px;
    background-color: #f5f5f5;
    border: none;
}

.height-max-content {
    height: max-content !important;
}

.title-divider {
    color: #212529;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before,
    &:after {
        content: "";
        width: 40px;
        display: inline-block;
        height: 2px;
        background-color: #B2B2B2;
        margin: 0px 10px;
    }
}

.invoice-preview__body {
    .classic-template__items {

        .py-table__body,
        .py-table__header {
            .py-table__row {

                td:nth-child(1),
                th:nth-child(1) {
                    padding-left: 0;
                }

                td:nth-child(4),
                th:nth-child(4) {
                    padding-right: 0;
                }
            }
        }
    }

    .classic-template {
        .py-invoice-template__totals-amounts {
            margin-right: 14px;
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.margin-25 {
    margin-top: 25%;
}

.downloadArea {
    margin-left: 5px;
    cursor: pointer;
}

.veriff-resume {
    width: 100%;
    background-color: #136acd;
    color: white;
    box-sizing: border-box;
    text-transform: uppercase;
    font-weight: 500 !important;
    font-size: 16px;
    line-height: 40px;
    letter-spacing: 0.4px;
    display: inline-block;
    text-align: center;
    user-select: none;
    border: 1px solid transparent;
    position: relative;
    height: 40px;
    padding: 0 16px;
    border-radius: 4px;
    transition-duration: 0.4s;
    transition-timing-function: ease-in-out;
    transition-property: background-color, color, border-color, box-shadow, transform;
    cursor: pointer;
}

.veriff-resume:hover, .veriff-resume:focus {
    transform: translateY(-2px);
    background-color: #136acd;
    border-color: #136acd;
}

#threeDSContainer {
    height: 310px !important;
}
.document-message {
    width: 330px;
}

.pageCenter {
    position: absolute;
    top: 40%;
    left: 20%;
}

.filter-icon-button {
    background-color: #ecf0f3 !important;
    border-color: #b2c2cd !important;
    color: #849194 !important;
    padding: 0px 10px !important;
    font-size: 20px !important;
    line-height: 38px;
    height: 40px;
    display: inline-block;
    border-radius: 4px;
}

.py-table .is-item-highlighted {
    background-color: #e1e9f4;
}
