.veriff-content-area {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    
    .vriff-image {
        margin-bottom: 30px;
    }
}