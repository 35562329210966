.mini-sidebar{
    overflow-y: auto;
    background: white;
    height: 100vh;
    overflow-x: hidden;
    box-sizing: border-box;
    // width: 250px;

    ul{
        padding-bottom: 1rem;

        
    }
}
