.mobile-landing-page {
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    min-height: 100%;
    padding: 24px 12px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 24px;
    display: flex;
    min-width: 300px;

    .wrapper {
        max-width: 1024px;
        margin: 0 auto;
    }

    .page-bg {
        position: absolute;
        right: -3px;
        top: -3px;
        width: 100%;
        z-index: -1;
    }

    .heading-1 {
        font-size: 36px;
        line-height: 46px;
        color: #00204b;
        font-weight: 700;
        margin-bottom: 40px;

        @media (max-width: 768px) {
            font-size: 26px;
            line-height: 36px;
        }
    }

    .mobile-screen {
        margin: 0px 0px -50px;

        img {
            width: 100%;
        }

        @media (max-width: 768px) {
            margin: 0px;
        }
    }

    .feature-box {
        text-align: center;
        margin: 40px 0px 0px;

        .box-title {
            font-size: 20px;
            line-height: 30px;
            color: #00204b;
            font-weight: 700;
        }

        .icon {
            margin-bottom: 20px;
        }
    }
}

.button-comings-soon {
    position: relative;
    cursor: not-allowed !important;
}

.button-comings-soon img {
    opacity: 0.1;
}

.button-comings-soon:after {
    content: "";
    width: 100%;
    height: 100px;
    background: url('../../assets/images/coming-text.png') no-repeat scroll center center / 100px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}