.advisors_wrapper {
    background: radial-gradient(112% 112% at 44.64% 96.43%, rgba(255, 255, 255, 0) 0%, rgba(197, 252, 252, 0.18) 100%);
    padding: 50px 0;

    .header-title {
        margin-bottom: 60px;
        text-align: center;

        .h2 {
            font-size: 44px;
            margin-bottom: 20px;
        }

        .desc {
            font-size: 20px;
        }
    }

    .header-text {
        .h3 {
            margin-bottom: 20px;
        }

        .desc {
            font-size: 18px;
            margin-bottom: 30px;
        }

        .icon-list {
            list-style: none;
            margin: 0 0 30px 0;
            padding: 0;

            li {
                margin-bottom: 15px;
                font-size: 16px;
                display: flex;
                align-items: flex-start;

                .icon {
                    margin-right: 10px;
                    display: inline-block;
                    line-height: 0;
                    margin-top: 2px;

                    .Icon {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
    .header-image {
        margin-right: -130px;
    }
    .service-list {
        text-align: center;
        margin-top: 70px;

        .h3 {
            margin-bottom: 50px;
            font-size: 34px;
        }
        .service-box {
            .title {
                margin-bottom: 10px;
                font-size: 24px;
            }

            .desc {
                font-size: 18px;
            }

            .icon {
                margin-bottom: 30px;

                .Icon {
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }
}