#pagination {
    .pagination {
        justify-content: flex-end;
        display: flex;
        padding-left: 0;
        list-style: none;
        border-radius: 4px;
        float: right;
        margin-top: 0px;

        li {
            position: relative;
            display: block;
            margin-left: -1px;
            line-height: 1.25;
            color: #136acd;
            background-color: #FFFFFF;
            border: 1px solid #E4E4E4;

            a {
                color: #136acd;
                padding: 0.5rem 0.8rem;
                display: block;
            }

            &:first-child {
                border-radius: 4px 0 0 4px;
            }

            &:last-child {
                border-radius: 0 4px 4px 0;
            }
        }

        li.active a {
            color: #FFFFFF;
        }

        li.active {
            z-index: 1;
            background-color: #136acd;
            border-color: #136acd;
            border-color: #136acd;
        }
    }
}
