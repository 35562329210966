$input-box-shadow: 0 0 0 1px rgba(42, 47, 69, .1),
0 2px 5px 0 rgba(42, 47, 69, .1),
0 1px 1.5px 0 rgba(0, 0, 0, .07),
0 1px 2px 0 rgba(0, 0, 0, .08),
0 0 0 0 transparent;

.form-control {
    display: inline-block;
    border-color: $border-color;
    border-radius: $border-radius;
    margin: 4px 0;
    box-shadow: $input-box-shadow;
    min-height: 40px;
    padding-top: 8px;
    padding-bottom: 8px;

    &:focus,
    &:active {
        border-color: $primary;
        border-width: 2px;
        // box-shadow: 0 0 0 4px rgba($primary, .1);
        box-shadow: none;
    }
}

.form-control-center {
    .form-control {
        border-radius: $border-radius;
    }
}

*:disabled,
[class*=is-disabled],
[class*=is-disabled] .input-group-text,
[class*=is-disabled] .Select-control,
[class*=is-disabled] .custom-select__control,
.react-datepicker-wrapper .react-DateRange__dateInput-container .form-control:disabled {
    cursor: not-allowed !important;
    border-color: darken($gray, 10%);
    color: darken($gray, 15%) !important;
}

[class*="is-disabled"] .input-group-text {
    background: darken($gray, 5%) !important;
    color: darken($gray, 15%) !important;
}

[class*="is-disabled"] .Select-control .Select-value .Select-value-label {
    color: darken($gray, 15%) !important;
}

[class*="is-disabled"] .custom-select__control .custom-select__value .custom-select__value-label {
    color: darken($gray, 15%) !important;
}


// @TODO: Maybe find ` better background image to emulate arrows
select.form-control {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=) no-repeat 95% 50%;
}

// .py-form__element__small.err, .py-form__element__xsmall.err{
//     input{
//         background-repeat: no-repeat;
//         background-position: center right calc(0.375em + 0.1875rem);
//         background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
//         border-color: red;
//         padding-right: calc(1.5em + 0.75rem);
//         background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fc605a' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fc605a' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
//     }
// }


.input-group {
    .input-group-text {
        border: 1px solid $border-color;

    }
}


// start:: alex
// Component:: Form fields

.py-checkbox,
.py-radio,
.form-control {

    [type="checkbox"]:focus,
    [type="checkbox"]:active,
    [type="radio"]:focus,
    [type="radio"]:active,
    [type="text"]:focus,
    [type="text"]:active,
    input:active,
    input:focus {
        ~.py-form__element__faux {
            border-color: #136acd;
            box-shadow: 0 0 0 3px rgba($primary, 0.2);
        }
    }

    &:disabled {
        background: #f9f9f9;
        border-color: #cccccc;

        &:focus,
        &:active {
            border-color: #b2c2cd !important;
            box-shadow: none !important;
        }
    }

    [type="checkbox"]:disabled,
    [type="checkbox"]:disabled,
    [type="radio"]:disabled,
    [type="radio"]:disabled {
        ~.py-form__element__faux {
            background: $gray-200;
            box-shadow: none;
            border: 0;
        }

        &:hover,
        &:focus,
        &:active {
            border: 0;
            box-shadow: none;

            ~.py-form__element__faux {
                border: 0;
                box-shadow: none;
            }
        }
    }

}

$text-muted: #4d6575;

.py-form-field {
    margin-bottom: 1rem;

    .py-text--emphasized {
        color: $text-muted;
        font-style: italic;
    }
}

.py-form-field__blank {
    display: none;
}

.is-required {
    &:after {
        content: '*';
        color: #d74242;
    }

    &:not(.no-abs) {
        position: relative;
        padding-right: 8px;

        &:after {
            position: absolute;
        }
    }
}


[contenteditable="true"] {
    @extend .form-control;
    min-height: 200px;
}

// Form inline
.py-form-field--inline {
    display: flex;
    align-items: start;

    &.v-center {
        align-items: center;
    }

    .py-form-field__label,
    .py-form-field__label--align-top {
        width: 34%;
        margin: 10px 14px 10px 0;
        text-align: right;
        position: relative;
        font-size: 16px;
    }

    .py-form-field__label___noWidth,
    .py-form-field__label___noWidth--align-top {
        margin: 10px 14px 10px 0;
        text-align: right;
        position: relative;
    }

    .py-form-field__element {
        width: 65%;
        vertical-align: top;
        margin-left: auto;
        text-align: left;

        .form-control {
            margin-top: 0px;
            margin-bottom: 0px;
        }

        .Select {
            min-height: 40px;
            min-width: 160px;
            max-width: 100%;
        }
    }


    .py-form-field__label--align-top {
        margin-top: 7px;
        margin-right: 30px;
    }
}

.py-form--horizontal {
    .py-form-field {
        margin-bottom: 8px;
    }
}


.py-form--vertical {

    .py-form-field {
        margin-bottom: 8px;
    }

    .py-form-field__label {
        margin-bottom: 4px;

        .text-danger {
            color: #c22929 !important;
            line-height: 10px;
            display: inline-block;
            height: 10px;
            transform: translateY(7px);
        }
    }

    .py-form-field__element {
        max-width: auto;
    }
}

label {
    .text-danger {
        color: #c22929 !important;
        font-size: 16px;
        line-height: 10px;
        display: inline-block;
        height: 10px;
        transform: translateY(7px);
    }
}

.py-form__element__small {
    max-width: 160px !important;
}

.py-form__element__xmedium {
    max-width: 140px;
}

.py-form__element__xsmall {
    max-width: 90px !important;
}

.py-form__element__medium {
    max-width: 320px !important;
}

.py-form__element__s_medium {
    max-width: 220px !important;
}

.py-form__element__large {
    max-width: 420px !important;
}

.py-input--large {
    padding: 12px 16px;
    line-height: 1.4;
    font-size: 20px;
    max-width: 480px;

    @include media-breakpoint-up(md) {
        font-size: 23px;
    }
}

.py-textarea {
    min-width: 160px;
}

.py-textarea__medium {
    max-width: 320px;
}

.py-form-fieldset {
    margin-bottom: 24px
}

.py-form-field__hint,
.py-text--hint {
    display: block;
    font-size: 14px;
    color: #4d6575;
    line-height: 1.4;
    white-space: pre-wrap;

    &.py-text__emphasized {
        display: inline-block;
    }
}


.py-form__element__color {
    width: 22px;
    height: 28px;
    padding: 0;
    background-image: url('/assets/icons/trigger.png') center no-repeat;
    display: inline-block;
    outline: none;
    border: 0;
    margin: 0 .25em;
}

.py-form__element__fluid {
    width: 100%;
    min-width: 100%;
    display: block;

    &.py-select--native {
        min-width: 100%;
        max-width: 100%;
        width: 100%;
    }
}


// Hide the default inputs in checkbox and radios
.py-radio,
.py-checkbox,
.py-switch,
.py-uploader {
    display: inline-flex;
    // cursor: pointer;
    margin: 4px 0;
    flex-wrap: wrap;

    .inputError {
        margin-top: -7px;
    }

    .errorMassage {
        width: 100%;
        font-size: 13px;
    }

    [type="checkbox"],
    [type="radio"],
    [type="file"] {
        width: 1px;
        height: 1px;
        border: 0;
        clip: rect(0 0 0 0);
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;

        &:disabled {
            cursor: not-allowed;

            +.py-form__element__faux {
                cursor: not-allowed;

                &:before {
                    display: none;
                }
            }

            ~.py-form__element__label {
                cursor: not-allowed;
            }
        }
    }



    +.py-form-field__hint {
        margin-left: 27px;
        margin-bottom: 8px;
        display: block;
    }

    img {
        width: 100%;
        height: 100%;
    }
}

.py-form-legend {
    padding: 0;
    text-align: left;
    color: #1c252c;
    font-weight: 600;
}

.py-form__element__faux {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    display: inline-block;
    position: relative;
    border: $input-border-width solid $border-color;
    vertical-align: middle;
    cursor: pointer;
    background-color: #ffffff;
}

.py-form__element__label {
    display: inline-block;
    vertical-align: middle;
    margin: 0 8px;
    cursor: pointer;
}

.py-radio,
.py-checkbox {

    .py-form__element__faux {
        position: relative;

        &:before {
            content: '';
            transform: scale(0);
            transition: transform .2s ease-in-out;
            transform-origin: center;
        }
    }

    &:focus {
        .py-form__element__faux {
            border-color: $primary;
            z-index: 1;
            background: #ffffff;

            &:before {
                width: 28px;
                height: 28px;
                background: rgba(19, 106, 205, 0.2);
                border-radius: 500px;
                transform: scale(1);
                position: absolute;
                top: -5px;
                left: -5px;
                z-index: -1;
            }
        }
    }
}

.py-input-group {
    display: flex;
    align-items: center;

    .form-control {
        &:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

.py-form-field__element {
    position: relative;
}

.py-form-field--condensed {

    .py-form-field,
    .form-group {
        margin-bottom: 10px;

        .slectedDatapy-form-field__label___noWidth--align-top,
        .py-form-field__label___noWidth {
            min-width: 88px;
            margin-right: 10px;
            padding: 10px 14px 10px 0;
            text-align: right;
        }


        .py-form-field__element {
            margin: 0;
            position: relative;

            .py-text--hint {
                margin-top: 10px;
            }

            .cal-icon {
                position: absolute;
                right: 3px;
                top: 5px;
                background-color: #ecf0f3;
                height: 38px;
                line-height: 34px;
                border-left: 1px solid #b2c2cd;
                color: #8aa2b2;
                border-radius: 0 5px 5px 0;
            }
        }
    }
}

.form-group {
    .py-form-field__label {
        margin-bottom: 4px;
        display: inline-block;
    }
}

.py-uploader {
    border: 1px dashed $primary;
    padding: 1rem;
    flex-direction: column;
    text-align: center;
    border-radius: 4px;

    .py-upload__cloud-logo {
        width: 60px;
        height: 60px;
        display: block;
        margin: auto;
    }
}

.Input__container {
    position: relative;
    margin-top: 8px;
    margin-bottom: 16px;

    .Input__label {
        position: absolute;
        padding: 0 4px;
        background: $white;
        font-size: 12px;
        top: -8px;
        left: 8px;
        color: $body-color;
    }

    select {
        min-height: 36px;
        padding: 16px 40px 12px 16px;
        width: 100%;
        appearance: none;
        -webkit-appearance: none;
        background-color: $white;
        border: 1px solid #C2C3C7;
        cursor: pointer;

        &:focus {
            border-width: 1px;
            border-color: $primary;
        }
    }

    .Icon {
        position: absolute;
        top: 20px;
        right: 16px;
        pointer-events: none;
    }

}

.form-control,
[contenteditable="true"],
body .py-datepicker--range .react-datepicker-wrapper .react-DateRange__dateInput-container,
body .DateRange__container {

    &:focus,
    &:active {
        border-width: 1px;
        border-color: #6eacf2;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(19, 106, 205, 0.25);
    }
}

.form-control {
    &.form-box-md {
        max-width: 320px;
        min-width: 160px;
        width: 100%;
    }

    &.form-box-sm {
        min-width: 160px;
        max-width: 160px;
    }
}



.invoice-add__body {
    .py-form-field {
        margin-bottom: 10px;
    }
}


.productServices-form {
    .py-form-field--inline {
        .py-form-field__element {
            width: calc(100% - 200px);

            @media (max-width: 768px) {
                width: calc(100% - 140px);
            }

            .Select {
                max-width: 220px;
            }
        }

        .py-form-field__label,
        .py-form-field__label--align-top {
            width: 200px;
            font-size: 16px;
            color: #1c252c;

            @media (max-width: 768px) {
                width: 140px;
            }
        }

        .py-checkbox {
            display: inline-block;

            .py-form__element__label {
                font-size: 11px;
                display: block;
                color: #bbbbbb;
                margin-left: 0px;
                margin-top: 5px;
            }
        }
    }
}

.py-text--samibold {
    font-weight: 600;
}

.banking-import-field {
    .is-required {
        padding-right: 12px !important;

        &:after {
            transform: translatey(0px);
        }
    }
}

.form-check {
    padding-left: 22px;

    .form-check-input {
        margin-left: 0px;
        left: 0px;
    }
}

.public-preview-page {
    .print-buttons {
        margin: 10px;

        .btn {
            margin: 5px;
        }
    }
}

.form-group {
    ::placeholder {
        color: #B2C2CD !important;
    }
}