.receipt-container{
    // * {margin: 0; padding: 0; box-sizing: border-box;}
    // body {font-size: 16px;
    //     color: #a0a0a5;
    //     font-weight: normal;
    //     vertical-align: top;}

    .Receipt header {display: block; box-shadow:0 0 14px rgba(0,0,0,.6); padding: 10px 0; margin: 0 0 18px;position: fixed; top: 0; left: 0; width: 100%; background: #fff; z-index: 10;}
    .Receipt header .container {display: block; width: 96%; margin: 0 auto;}
    .Receipt header .container::after {content: ''; display: block; clear: both;}
    .Receipt header .txt {display: inline-block; vertical-align: middle; width: calc(100% - 200px - 8px); text-align: left; line-height: 40px;}
    .Receipt header .goBack {display: inline-block; width: 200px;}
    .Receipt header .goBack a {display: block; line-height: 40px; border: 1px solid #b2c2cd; border-radius: 20px; text-align: center; font-weight: 700; color: #4d6575; text-decoration: none;}

    .Receipt {display: block; width: 460px; margin: 80px auto 0; border: 1px solid #e1e1e1; text-align: center; padding-top: 27px; border-radius: 0 0 10px 10px; overflow: hidden;}
    .Receipt .logo {display: block; margin: 0 0 20px;}
    .Receipt .logo img {width: 180px;}
    .Receipt h2 {color: #2f2f36; font-size: 32px; line-height: 32px;}
    .Receipt span.receiptNo {display: block; font-size: 18px; color: #444; font-weight: $font-weight-bold; margin: 0 0 5px; text-transform: lowercase;}
    .Receipt span.for {display: block; font-size: 16px; color: #a0a0a5;  margin: 0 0 5px; margin: 0 0 32px;}
    .Receipt p {font-size: 12px; line-height: 18px; color: #a0a0a5; font-weight: $font-weight-bold; margin: 0 0 20px;}
    .Receipt .sep {display: block; position: relative; border-bottom: 1px solid #e1e1e1; margin: 0 0 27px;}
    .Receipt .sep::before {content: ''; display: block; width: 34px; height: 14px; background: #fff url(/assets/checkmark-reminder-email.jpg) center no-repeat; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);}
    .Receipt .payD {display: block; margin: 0 0 27px;}
    .Receipt .payD .amt, .Receipt .payD .method {display: block; border-top: 1px solid #e1e1e1; padding: 12px 0; margin: 0 28px; color: #444;}
    .Receipt .payD .amt {font-size: 18px; line-height: 40px;}
    .Receipt .payD .method {font-size: 12px; text-transform: uppercase; line-height: 40px;}
    .Receipt .thanks {padding: 20px 27px; border-top: 1px solid #e1e1e1;}
    .Receipt .thanks a {color: #136acd; text-decoration: none; font-weight: 700; font-size: 14px;}
    .Receipt .foot {display: block; height: 26px; background: #f0f0f0; border-top: 1px solid #e1e1e1;}

    .poweredBy {display: block; width: 460px; margin: 0 auto; padding: 20px 0; text-align: center;}
    .poweredBy .poweredLogo {display: inline-block; background: url(/assets/powered-by.jpg) center no-repeat; background-size: 132.8px auto; width: 174px; height: 40px; border: 1px solid #e1e1e1; border-radius: 20px;}
    .Receipt .businessInfo-reciept{
        font-weight: bold;
        font-size: 14px;
        color: #4d6575;
      }
}

.receipts-list {
    .py-table {
        .py-table__cell,
        .py-table__cell__action,
        .py-table__cell-amount {
            .badge {
                min-width: 95px;
            }
        }
    }
}