.countdown {
    text-transform: uppercase;
    font-weight: bold;
}

.countdown span {
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    font-size: 3rem;
    margin-left: 0.8rem;
}

.countdown span:first-of-type {
    margin-left: 0;
}

.countdown-circles {
    text-transform: uppercase;
    font-weight: bold;
}

.countdown-circles span {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.countdown-circles span:first-of-type {
    margin-left: 0;
}
.coming-soon-container {
    min-height: 80vh;
}

.debit-cards-commingsoon {
    background: url('../../assets/images/debit-card/blue-debit-card-page.png') no-repeat scroll top center / 100% auto;
    overflow: hidden;
    position: relative;
    z-index: 1;    
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media (max-width: 1800px) {
        background-image: url('../../assets/images/debit-card/debit-card-page-sm.png');
    }
    
    .py-header--page {
        display: none;
    }

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.6);
        z-index: -1;
    }

    .py-content {
        display: none;
    }

    .py-header__title {
        background-color: rgba(255, 255, 255, 1);
        padding: 50px;
        border-radius: 30px;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    }
    
    .py-heading--title {
        margin-top: 0;
    }
    
    .py-heading--subtitle {
        margin-bottom: 0;
        margin-top: 15px;
    }
}

.table-row {
    height: 50px;
    vertical-align: middle;
}

.cursor-none {
    pointer-events: none;
}

